import React, { useEffect, useState } from 'react';

import { useToast } from '@chakra-ui/core';
import { fetchAll, deleteEvent } from 'api/events';
import * as R from 'ramda';
import { useMutation, useQuery } from 'react-query';
import { EventItem } from 'types/events';

import featureFlags from '../../constants/featureFlags';
import { EventsProps } from './Events.props';
import EventsView from './Events.view';

const EventsContainer = (props: EventsProps): JSX.Element => {
  const [showFilters, setShowFilters] = useState(false);
  const [columnToSort, setColumnToSort] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [deletedEvents, setDeletedEvents] = useState<Array<number>>([]);

  const { data = [], isLoading, refetch } = useQuery(`events list`, fetchAll);

  const [events, setEvents] = useState(data);
  const [upcoming, setUpcoming] = useState(false);
  const [ongoing, setOngoing] = useState(false);
  const [expired, setExpired] = useState(false);
  const filters = {
    ongoing,
    upcoming,
    expired,
  };
  useEffect(() => {
    if (window.innerWidth > 1000) setShowFilters(true);
  }, []);

  const [deleteSelectedEvent, { isLoading: isDeleting }] = useMutation(
    deleteEvent,
    {
      onSuccess: () => {
        toast({
          title: 'Success',
          description: 'Event deleted successfully',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
      },
    }
  );

  const toast = useToast();

  useEffect(() => {
    document.body.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    document.body.style.overflow = 'hidden';
  }, []);

  useEffect(() => {
    if (data.length && !isLoading) {
      setEvents(data);
    }
  }, [data, isLoading]);

  const handleToggle = () => setShowFilters(!showFilters);
  const handleSearch = (text: string) => setSearchQuery(text);
  const onEventClick = (eventId: number) => {
    props.history.push(`/event/${eventId}`);
  };
  const onNewClick = () => {
    props.history.push(`/event/create`);
  };

  const sortBy = (key: string) => {
    setColumnToSort(key);
    const byKey =
      columnToSort === key
        ? R.descend<any>(R.prop(key))
        : R.ascend<any>(R.prop(key));
    const sortedData = R.sort(byKey, data);
    setEvents(sortedData);
  };

  const filteredEvents = events.filter((item) => {
    return (
      !deletedEvents.includes(item.id) &&
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const onCopyClick = (e: any, selectedEventId: number) => {
    e.stopPropagation();
    props.history.push(`/event/create?eventId=${selectedEventId}`);
  };

  const updateEventList = async (selectedEventId: number) => {
    const updatedEvents: EventItem[] | undefined = await refetch();
    setDeletedEvents([...deletedEvents, selectedEventId]);
    setEvents(updatedEvents || []);
  };

  const onDeleteClick = async (selectedEventId: number) => {
    if (!featureFlags.enableEventsDeletion) {
      return;
    }

    try {
      deleteSelectedEvent(selectedEventId);
      updateEventList(selectedEventId);
    } catch (e) {
      toast({
        title: 'Error',
        description: 'Something went wrong while deleting the event',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const onFilter = (filter: string) => {
    const today = new Date();
    const filteredData: any = [];
    //filter upcoming
    if (filter === 'upcoming') {
      if (!upcoming) {
        setUpcoming(true);
        setOngoing(false);
        setExpired(false);
        data.forEach((event) => {
          if (new Date(event.start) > today) filteredData.push(event);
        });
        setEvents(filteredData);
      } else {
        setUpcoming(false);
        setEvents(data);
      }
    }
    //filter ongoing
    if (filter === 'ongoing') {
      if (!ongoing) {
        setOngoing(true);
        setUpcoming(false);
        setExpired(false);
        data.forEach((event) => {
          if (new Date(event.start) < today && new Date(event.end) > today) {
            filteredData.push(event);
          }
        });
        setEvents(filteredData);
      } else {
        setOngoing(false);
        setEvents(data);
      }
    }
    //filter expired
    if (filter === 'expired') {
      if (!expired) {
        setExpired(true);
        setUpcoming(false);
        setOngoing(false);
        data.forEach((event) => {
          if (new Date(event.end) < today) filteredData.push(event);
        });
        setEvents(filteredData);
      } else {
        setExpired(false);
        setEvents(data);
      }
    }
  };

  return (
    <EventsView
      data={filteredEvents}
      isLoading={isLoading}
      onEventClick={onEventClick}
      onNewClick={onNewClick}
      handleToggle={handleToggle}
      showFilters={showFilters}
      sortBy={sortBy}
      handleSearch={handleSearch}
      onCopyClick={onCopyClick}
      onDeleteClick={onDeleteClick}
      isDeleting={isDeleting}
      onFilter={onFilter}
      filters={filters}
    />
  );
};

export default EventsContainer;
