import api from 'api/instance';
import { EventActivityScoringRule, ScoringRules } from 'types/events';

const URL = `${process.env.REACT_APP_API_URL}/events/scoring-rules`;

export const fetchScoringRules = async (id: number): Promise<ScoringRules> => {
  const { data } = await api.get<ScoringRules>(`${URL}?challenge_id=${id}`);
  return data;
};

export const createScoringRule = async (
  details: EventActivityScoringRule
): Promise<any> => {
  const { data } = await api.post(`${URL}/create`, details);
  return data;
};

export const deleteScoringRule = async (
  details: Omit<
    EventActivityScoringRule,
    'metric_unit' | 'metric_value' | 'point_value'
  >
): Promise<any> => {
  const { data } = await api.get(`${URL}/delete`, {
    params: {
      ...details,
    },
  });
  return data;
};

export const updateScoringRule = async (
  detials: EventActivityScoringRule
): Promise<any> => {
  const { data } = await api.post(`${URL}/update`, detials);
  return data;
};
