import React from 'react';

import { useToast } from '@chakra-ui/core';
import { create, fetchEventDetails } from 'api/events';
import moment from 'moment';
import qs from 'qs';
import { useMutation, useQuery } from 'react-query';
import { Admission, Event } from 'types/events';

import { EventCreateProps } from './EventCreate.props';
import EventCreateView from './EventCreate.view';

const startDate = moment(new Date())
  .utc()
  .tz('America/New_York')
  .set({ hours: 0, minutes: 0, seconds: 0 })
  .toISOString();

const endDate = moment(new Date())
  .utc()
  .tz('America/New_York')
  .set({ hours: 23, minutes: 59, seconds: 59 })
  .toISOString();

const emptyEvent = {
  name: '',
  description: '',
  logo: null,
  logo_thumbnail: null,
  start: startDate,
  end: endDate,
  end_registration: endDate,
  rules: null,
  prize_pool: 0,
  prize_description: null,
  p2p_message: '',
  fundraising_goal: 0,
  free_admission_code: '',
  teams: false,
  paid: false,
  p2p: true,
  use_average: false,
  streak_bonus: true,
  extra_mile_bonus: true,
  sponsor_donations: 0,
  draft: true,
  tax_receipt: false,
  donate_button: false,
  use_custom_scoring: false,
  allow_manual_activity: false,
  allow_partial_credit: false,
  point_limit_per_day: 0,
  website_url: '',
  short_description: '',
  start_registration: startDate,
  start_fundraising: startDate,
  end_fundraising: endDate,
  redirect_on_join: false,
  join_redirect_url: '',
  enterprise: false,
  public: false,
};

const EventCreateContainer = (props: EventCreateProps): JSX.Element => {
  const toast = useToast();
  const [createEvent, { isLoading }] = useMutation(create, {
    onSuccess: () => {
      window.scrollTo({ top: 0 });
      props.history.push('/events');
      toast({
        title: 'Success',
        description: 'Event created successfully',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    },
    onError: (err: any) => {
      toast({
        status: 'error',
        title: 'Error',
        description: String(err?.Message).toString() || 'Something went wrong',
        isClosable: true,
        duration: 5000,
      });
    },
  });

  const queryString: { eventId?: string } = qs.parse(props.location.search, {
    ignoreQueryPrefix: true,
  });

  const { eventId } = queryString;

  const { data, isLoading: isFetchingEvent } = useQuery(
    `fetch-event-${eventId}`,
    async () => await fetchEventDetails(parseInt(eventId || '0')),
    {
      enabled: !!eventId,
    }
  );

  const onSubmit = (
    details: Partial<
      Event & { admission: Omit<Admission, 'id' | 'challenge_id'>[] }
    >
  ) => {
    createEvent({
      event: details as Event,
      admission: details.admission || [],
      prizes: [],
      sponsors: [],
      teams: [],
      scoring_rules: [],
    });
  };

  return (
    <EventCreateView
      onSubmit={onSubmit}
      isLoading={isLoading}
      event={data?.event || emptyEvent}
      isFetchingEvent={isFetchingEvent}
    />
  );
};

export default EventCreateContainer;
