import React, { useState } from 'react';

import { Flex, Stack, Button, Text, Icon } from '@chakra-ui/core';
import { getAdmissionMerch } from 'api/admissionPriceTiers';
import { getAllMerch } from 'api/merch';
import ConfirmationModal from 'components/primitives/ConfirmationModal';
import FormikInputView from 'components/primitives/FormikInput';
import FormikNumberInputView from 'components/primitives/FormikNumberInput';
import FormikSelectInput from 'components/primitives/FormikSelectInput';
import FormikTextAreaView from 'components/primitives/FormikTextArea';
import { Form, Formik } from 'formik';
import * as R from 'ramda';
import { useQuery } from 'react-query';
import { Admission } from 'types/events';
import { AdmissionMerch } from 'types/merch';

import { AdmissionTiersGeneratedProps } from './AdmissionTiers.props';

const AdmissionMerchForm = ({
  admissionId,
  onMerchAdd,
  onMerchUpdate,
  onMerchItemDelete,
  isMerchItemDeleting,
}: {
  admissionId: number;
  onMerchAdd: (values: AdmissionMerch) => Promise<any>;
  onMerchUpdate: (values: AdmissionMerch) => Promise<any>;
  onMerchItemDelete: (values: AdmissionMerch) => Promise<any>;
  isMerchItemDeleting: boolean;
}) => {
  const { data, refetch, isLoading } = useQuery(
    `get-merch-item-${admissionId}`,
    () => getAdmissionMerch(admissionId)
  );
  const { data: merchList } = useQuery('fetch-merch', getAllMerch);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<string | null>(null);

  return (
    <Flex flex={1} direction={['column', 'row']} py={6}>
      <Flex flex={1} direction="column" maxW="360px">
        <Formik<AdmissionMerch>
          initialValues={
            isEditing && selectedGroup
              ? {
                  admission_id: admissionId,
                  current_group_name: selectedGroup,
                  new_group_name: '',
                  merch_id: 0,
                }
              : {
                  admission_id: admissionId,
                  merch_selection_group: '',
                  merch_id: 0,
                }
          }
          enableReinitialize
          onSubmit={async (values, { resetForm }) => {
            const params = {
              ...values,
              merch_id: parseInt(values.merch_id.toString()),
            };
            if (isEditing && selectedGroup) {
              await onMerchUpdate(values);
            } else {
              await onMerchAdd(params);
            }
            refetch();
            resetForm();
            setSelectedGroup(null);
            setIsEditing(false);
          }}
        >
          {({ isSubmitting, initialValues, values }) => (
            <Form>
              {!isEditing ? (
                <>
                  <FormikInputView
                    name="merch_selection_group"
                    label="SELECTION GROUP"
                  />
                  <FormikSelectInput
                    name="merch_id"
                    placeholder="Select Merch Item"
                    label="MERCH ITEM"
                  >
                    {merchList?.map((item, index) => (
                      <option key={`${item.id}-${index}`} value={item.id}>
                        {item.label}
                        {'  ' + item.size}
                      </option>
                    ))}
                  </FormikSelectInput>
                </>
              ) : (
                <>
                  <FormikInputView
                    name="current_group_name"
                    label="CURRENT GROUP NAME"
                    isReadOnly
                  />
                  <FormikInputView
                    name="new_group_name"
                    label="NEW GROUP NAME"
                  />
                </>
              )}
              <Stack isInline alignItems="center" spacing={4} mt={4}>
                <Button
                  type="submit"
                  w="100px"
                  bg={isEditing ? 'yellow.500' : 'green.300'}
                  fontSize="12px"
                  fontWeight="bold"
                  color="white"
                  isLoading={isSubmitting}
                  isDisabled={R.equals(initialValues, values)}
                >
                  {isEditing ? 'UPDATE' : 'SAVE'}
                </Button>
                {isEditing && (
                  <Button
                    w="100px"
                    bg="red.300"
                    color="white"
                    fontSize="12px"
                    fontWeight="bold"
                    onClick={() => {
                      setSelectedGroup(null);
                      setIsEditing(false);
                    }}
                  >
                    CLEAR
                  </Button>
                )}
              </Stack>
            </Form>
          )}
        </Formik>
      </Flex>
      <Flex flex={1} direction="column" pl={[0, 6]} mt={[8, 0]}>
        {isLoading ? (
          <Text>Loading...</Text>
        ) : (
          data?.groups?.map((group, gIndex) => (
            <Flex
              borderWidth="1px"
              p={2}
              mb={2}
              key={`${group.label}-${gIndex}`}
              direction="column"
            >
              <Stack isInline alignItems="center" mb={1}>
                <Text>{gIndex + 1})</Text>
                <Text fontWeight="700">{group.label}</Text>
                <Icon
                  name="edit"
                  cursor="pointer"
                  ml={4}
                  onClick={() => {
                    setIsEditing(true);
                    setSelectedGroup(group.label);
                  }}
                />
              </Stack>
              <Flex
                ml={[4, 8]}
                fontSize="12px"
                fontWeight="500"
                color="grey"
                px={2}
                mb={1}
              >
                <Text
                  mr={1}
                  flex={[1, 0.3]}
                  textDecoration="underline"
                  textAlign="center"
                  isTruncated
                >
                  MERCH ID
                </Text>
                <Text flex={1} textDecoration="underline">
                  MERCH LABEL
                </Text>
                <Text
                  isTruncated
                  flex={[1, 0.3]}
                  textDecoration="underline"
                  textAlign="center"
                >
                  MERCH SIZE
                </Text>
                <Flex flex={1} />
              </Flex>
              {group.options.map((item, index) => {
                return (
                  <Flex
                    key={`${item.label}-${index}`}
                    ml={[4, 8]}
                    fontSize="14px"
                    px={2}
                    mb={1}
                  >
                    <Text mr={1} flex={[1, 0.3]} textAlign="center">
                      {item.id}
                    </Text>
                    <Text flex={1}>{item.label}</Text>
                    <Text flex={[1, 0.3]} textAlign="center">
                      {item.size}
                    </Text>
                    <Flex flex={1} alignItems="center">
                      <ConfirmationModal
                        message="Are you sure you want to permanently remove this merch item?"
                        onConfirmClick={async () => {
                          await onMerchItemDelete({
                            admission_id: data.admission_id,
                            merch_id: item.id || 0,
                            merch_selection_group: group.label,
                          });
                          refetch();
                        }}
                        isLoading={isMerchItemDeleting}
                      >
                        <Icon name="delete" size="12px" ml={8} />
                      </ConfirmationModal>
                    </Flex>
                  </Flex>
                );
              })}
            </Flex>
          ))
        )}
      </Flex>
    </Flex>
  );
};

const AdmissionTiersView = (
  props: AdmissionTiersGeneratedProps
): JSX.Element => {
  const [editTier, setEditTier] = useState<Admission | null>(null);
  const [isEditingTier, setIsEditingTier] = useState(false);

  return (
    <Flex flex={1} direction="column" pb={20}>
      <Formik
        enableReinitialize
        initialValues={
          editTier
            ? editTier
            : {
                label: '',
                price: 0,
                description: '',
              }
        }
        onSubmit={async (values, formik) => {
          const params = {
            challenge_id: props.challengeId,
            ...values,
          };
          formik.setSubmitting(true);
          if (!isEditingTier) {
            await props.onCreate(params);
          } else {
            await props.onUpdate(params);
            setEditTier(null);
            setIsEditingTier(false);
          }
          formik.setSubmitting(false);
        }}
      >
        {({ isSubmitting, values, initialValues }) => (
          <Form>
            <Flex flex={1} direction={['column', 'row']}>
              <Flex flex={1} direction="column">
                <Flex flex={1} direction="column">
                  <FormikInputView name="label" label="LABEL" />
                  <FormikNumberInputView isPrice name="price" label="PRICE" />
                  <FormikTextAreaView
                    name="description"
                    label="DESCRIPTION"
                    size="sm"
                  />
                </Flex>
                <Stack isInline spacing={4}>
                  <Button
                    w="100px"
                    bg={isEditingTier ? 'yellow.500' : 'purple.900'}
                    color="white"
                    fontSize="12px"
                    mt={5}
                    type="submit"
                    isLoading={isSubmitting}
                    fontWeight="bold"
                    isDisabled={R.equals(initialValues, values)}
                  >
                    {isEditingTier ? 'UPDATE' : 'ADD NEW'}
                  </Button>
                  {isEditingTier && (
                    <Button
                      w="100px"
                      bg="red.300"
                      color="white"
                      fontSize="12px"
                      mt={5}
                      fontWeight="bold"
                      onClick={() => {
                        setEditTier(null);
                        setIsEditingTier(false);
                      }}
                    >
                      CLEAR
                    </Button>
                  )}
                </Stack>
              </Flex>
              <Flex
                flex={2}
                bg="white"
                direction="column"
                fontSize={['12px', '14px']}
                fontWeight="600"
                ml={[0, 5]}
                mt={[5, 0]}
                p={3}
                borderWidth={1}
              >
                <Flex fontWeight="bold" mb={2}>
                  <Text flex={0.2}>NO</Text>
                  <Text flex={0.5} mr={2}>
                    LABEL
                  </Text>
                  <Text flex={0.5} isTruncated mr={1}>
                    DESCRIPTION
                  </Text>
                  <Text flex={0.3} ml={2}>
                    PRICE
                  </Text>
                  <Flex flex={0.15} ml={2} />
                </Flex>
                {props.admissionTiers
                  ? props.admissionTiers.map((item, index) => {
                      return (
                        <Flex
                          key={`${item.id}`}
                          py={2}
                          borderBottomWidth="1px"
                          alignItems="center"
                        >
                          <Text flex={0.2}>{index + 1})</Text>
                          <Text flex={0.5} isTruncated mr={2}>
                            {item.label}
                          </Text>
                          <Text flex={0.5} isTruncated mr={1}>
                            {item.description}
                          </Text>
                          <Text flex={0.3} ml={2}>
                            $ {item.price}
                          </Text>
                          <Icon
                            flex={0.1}
                            name="edit"
                            cursor="pointer"
                            onClick={() => {
                              setEditTier(props.admissionTiers[index]);
                              setIsEditingTier(true);
                            }}
                          />
                          <ConfirmationModal
                            message="Are you sure you want to permanently delete this price tier?"
                            onConfirmClick={async () =>
                              await props.onDelete(item?.id)
                            }
                            isLoading={props.isTierDeleting}
                          >
                            <Icon name="delete" size="14px" mb={1} />
                          </ConfirmationModal>
                        </Flex>
                      );
                    })
                  : 'No Tiers Added'}
              </Flex>
            </Flex>
          </Form>
        )}
      </Formik>
      {isEditingTier && editTier && (
        <Flex flex={1} direction="column" my={20}>
          <Text fontSize="18px" fontWeight="600">
            Admission Merch Items for Price Tier :- {editTier?.label}
          </Text>
          <AdmissionMerchForm
            admissionId={editTier.id}
            onMerchAdd={props.onMerchAdd}
            onMerchItemDelete={props.onMerchItemDelete}
            isMerchItemDeleting={props.isMerchItemDeleting}
            onMerchUpdate={props.onMerchUpdate}
          />
        </Flex>
      )}
    </Flex>
  );
};

export default AdmissionTiersView;
