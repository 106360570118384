import ReactDomServer from 'react-dom/server';
import HtmlParser, { convertNodeToElement } from 'react-html-parser';

export const getEventInfoTemplate = (): string =>
  `<p><strong>Kilter Activity-Based Challenge</strong></p><p><strong>Event Start Date: Friday, January 1, 2020</strong><br/><strong>Event End Date: Sunday, January 31, 2020</strong></p><p>Participate in this fitness challenge to keep yourself honest over the next month! Compete daily in this event and support this fantastic cause by merely staying active and healthy. Kilter makes that easy: do something active for 30 minutes a day, earn points, compete against your friends and other participants – and most of all – have fun!</p><p>This event is <strong>OPEN</strong> to the public. Invite your friends, family and colleagues to join you in your pursuit to make a positive impact on the causes awareness as you keep each other motivated to be healthy and active.</p><p><strong>EVENT INFO</strong>:</p><p>Entry Fee: $30<br/><em>*90% of your entry fee goes directly to support PCOS Awareness Association and the prize pool in this event!</em></p><p></p><p><strong>PRIZES</strong>:</p><p>Top Point Earner and Donation Rasier: <br/>5% of all Registration Fees!</p><p><strong>Getting Started</strong>:</p><p>1) Click JOIN and pay your entry fee.</p><p>2) View the My Events tab and confirm that you&#x27;re in!</p><p>3) During the event, complete daily wellness activities to earn points for your personal score. Stay at the top of the leaderboard for your chance to win cool prizes (see rules tab for exercise list)!</p><p></p>`;

// link format helper
export const formatLink = (text: string) => {
  const transform = (node: any, index: number) => {
    if (node.type === 'tag' && node.name === 'a') {
      node.attribs.href = node.attribs.href.replace(/^http:\/\//i, 'https://');
      return convertNodeToElement(node, index, transform);
    }
  };

  const html = HtmlParser(text, {
    transform,
  });
  //@ts-ignore
  const formattedHtml = ReactDomServer.renderToStaticMarkup(html);
  return { formattedHtml };
};
