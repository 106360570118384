import React, { useEffect, useState } from 'react';

import { FormControl, FormLabel, Text, FormHelperText } from '@chakra-ui/core';
import { ContentState, EditorState, convertToRaw, Modifier } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useField } from 'formik';
import htmlToDraft from 'html-to-draftjs';
import { Editor, SyntheticKeyboardEvent } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { FormikEditorProps } from './FormikEditor.props';
import { formatLink, getEventInfoTemplate } from './FormikEditor.utils';

const FormikEditorView = ({
  name,
  label,
  helperText,
  error,
  ...props
}: FormikEditorProps): JSX.Element => {
  const [{ value }, meta, field] = useField<string>(name);
  const defaultTemplate = getEventInfoTemplate();
  const blocksFromHtml = htmlToDraft(value || defaultTemplate);
  const [localValue, setLocalValue] = useState(EditorState.createEmpty());
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (!isEditing) {
      setLocalValue(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            blocksFromHtml.contentBlocks,
            blocksFromHtml.entityMap
          )
        )
      );
    }
    // eslint-disable-next-line
  }, [value]);

  const onChange = (editorState: EditorState) => {
    setLocalValue(editorState);
    const toHtml = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    const { formattedHtml } = formatLink(toHtml);
    if (formattedHtml === '<p></p>') {
      field.setValue('');
    } else {
      field.setValue(formattedHtml);
    }
  };

  const onTab = (e: SyntheticKeyboardEvent) => {
    e.preventDefault();
    const currentState = localValue;
    const modifiedState = Modifier.replaceText(
      currentState.getCurrentContent(),
      currentState.getSelection(),
      '    '
    );
    const newState = EditorState.push(
      currentState,
      modifiedState,
      'insert-characters'
    );
    setLocalValue(newState);
    const toHtml = draftToHtml(convertToRaw(newState.getCurrentContent()));
    field.setValue(toHtml);
  };

  return (
    <FormControl mt={2}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <Editor
        {...props}
        onTab={onTab}
        editorState={localValue}
        onEditorStateChange={onChange}
        wrapperStyle={styles.wrapper}
        editorStyle={styles.editor}
        toolbarStyle={styles.toolbar}
        handlePastedText={() => false}
        onFocus={(e) => {
          e.preventDefault();
          setIsEditing(true);
        }}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {(error || meta.error) && (
        <Text color="red.500" fontSize="sm">
          {error || meta.error}
        </Text>
      )}
    </FormControl>
  );
};

const styles = {
  wrapper: {
    padding: '1rem',
    border: '1px solid #ccc',
    lineHeight: 1,
    maxWidth: '800px',
  },
  editor: {
    padding: '1rem',
    border: '1px solid #ccc',
    height: '500px',
    overflow: 'auto',
  },
  toolbar: {
    border: '1px solid #ccc',
  },
};

export default FormikEditorView;
