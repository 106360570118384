import React, { useEffect, useState } from 'react';

import {
  Flex,
  Heading,
  Image,
  ModalOverlay,
  Spinner,
  useToast,
} from '@chakra-ui/core';
import { useField } from 'formik';
import { useDropzone } from 'react-dropzone';
import PlaceHolderImg from 'res/placeholder.png';

import { S3_BASE } from 'constants/images';

import { FormikImageUploadProps } from './FormikImageUpload.props';

const FormikImageUploadView = ({
  preview = true,
  ...props
}: FormikImageUploadProps): JSX.Element => {
  const [file, setFile] = useState<File & { preview: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length) {
        setIsLoading(true);
        setFile(
          Object.assign(acceptedFiles[0], {
            preview: URL.createObjectURL(acceptedFiles[0]),
          })
        );
      }
    },
  });
  const [{ value }, , field] = useField(props.name);

  useEffect(() => {
    const doUpload = async (newFile: File) => {
      props
        .uploadHandler(newFile)
        .then((imgName) => {
          field.setValue(imgName);
          setIsLoading(false);
        })
        .catch((e) => {
          console.log(e);
          toast({
            title: 'Image Upload Error',
            status: 'error',
            isClosable: true,
            duration: 9000,
          });
          setIsLoading(false);
        });
    };
    if (file) {
      doUpload(file);
    }
    return () => {
      // Make sure to revoke the data uris to avoid memory leaks
      if (file) URL.revokeObjectURL(file.preview);
    };
    // eslint-disable-next-line
  }, [file]);

  useEffect(() => {
    if (isLoading) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isLoading]);

  if (!preview) {
    return (
      <Flex
        align="center"
        {...props.flexProps}
        {...getRootProps()}
        h="40px"
        borderWidth="1px"
        borderRadius="5px"
      >
        {isLoading && (
          <ModalOverlay
            display="flex"
            flex={1}
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Spinner
              size="xl"
              color="white"
              zIndex={10}
              thickness="5px"
              speed="0.65s"
            />
            <Heading
              textAlign="center"
              fontSize="2xl"
              color="white"
              fontWeight="bold"
              zIndex={10}
            >
              Generating the pre-signed-url for image...
            </Heading>
          </ModalOverlay>
        )}
        <input
          {...getInputProps()}
          placeholder={file?.name || 'Select an Image'}
          style={{
            width: '100%',
            overflow: 'hidden',
          }}
        />
      </Flex>
    );
  }
  return (
    <Flex
      width="100%"
      maxWidth={`${props.width || 250}px`}
      justify="space-between"
      align="center"
      {...props.flexProps}
      {...getRootProps()}
    >
      {isLoading && (
        <ModalOverlay
          display="flex"
          flex={1}
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Spinner
            thickness="5px"
            speed="0.65s"
            size="xl"
            color="white"
            zIndex={10}
          />
          <Heading
            textAlign="center"
            fontSize="2xl"
            color="white"
            fontWeight="bold"
            zIndex={10}
          >
            Generating the pre-signed-url for image...
          </Heading>
        </ModalOverlay>
      )}
      <Image
        width={props.width || 250}
        height={props.height || 250}
        {...props.imageProps}
        src={
          file?.preview ||
          (value ? `${S3_BASE}/${props.s3Key}/${value}` : PlaceHolderImg)
        }
      />
      <input {...getInputProps()} />
    </Flex>
  );
};

export default FormikImageUploadView;
