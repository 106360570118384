import React from 'react';

import { Button, Flex, Heading } from '@chakra-ui/core';

import EventDetails from 'components/modules/EventDetails';
import LoadingIndicator from 'components/common/LoadingIndicator';

import { EventCreateGeneratedProps } from './EventCreate.props';

const EventCreateView = (props: EventCreateGeneratedProps): JSX.Element => (
  <Flex p={5} flex="1" bg="white" flexDir="column" alignItems="center">
    <Flex flexDir="column" maxWidth="980px" width="100%">
      <Heading textAlign="center" color="cyan.900">
        Create Event
      </Heading>
      {props.isFetchingEvent ? (
        <LoadingIndicator />
      ) : (
        <EventDetails data={props.event} onSubmit={props.onSubmit}>
          <Button
            mt={3}
            size="lg"
            type="submit"
            color="white"
            bg="purple.900"
            alignSelf="flex-end"
            isLoading={props.isLoading}
            isDisabled={props.isLoading}
          >
            Create
          </Button>
        </EventDetails>
      )}
    </Flex>
  </Flex>
);

export default EventCreateView;
