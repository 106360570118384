import React from 'react';

import {
  Box,
  Flex,
  Text,
  Icon,
  Stack,
  Input,
  Badge,
  Switch,
  Button,
  Collapse,
  Skeleton,
  FormLabel,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/core';

import moment from 'moment';

import { range } from 'ramda';

import featureFlags from '../../constants/featureFlags';

import ConfirmationModalView from 'components/primitives/ConfirmationModal/ConfirmationModal.view';

import { EventItem } from 'types/events';
import { EventsGeneratedProps } from './Events.props';

const eventStatus = (item: EventItem) => {
  let status = 'expired';
  let color = 'red';
  const today = new Date();
  if (new Date(item.start) > today) {
    status = 'upcoming';
    color = 'blue';
  }
  if (new Date(item.start) < today && new Date(item.end) > today) {
    status = 'ongoing';
    color = 'green';
  }
  return (
    <Text
      pl={1}
      pr={1}
      w={['50px', '50px', '60px']}
      textAlign="center"
      bg={`${color}.100`}
      fontSize={['8px', '8px', '10px']}
      borderRadius="20px"
      color={`${color}.600`}
      borderWidth="1px"
      borderColor={`${color}.200`}
    >
      {status}
    </Text>
  );
};

export const ListLoadingIndicator = (): JSX.Element => (
  <>
    {range(0, 15).map((item) => (
      <Skeleton
        key={item}
        colorStart="#0985"
        colorEnd="#0981"
        height="35px"
        mb={1}
        mt={1}
      />
    ))}
  </>
);

const DeleteIcon = ({
  onDeleteClick,
}: {
  onDeleteClick: () => Promise<any>;
}) => {
  if (!featureFlags.enableEventsDeletion) {
    return <Icon name="delete" size="16px" color="grey" />;
  }

  return (
    <ConfirmationModalView
      message="Are you sure you want to permanently delete this event?"
      onConfirmClick={onDeleteClick}
    >
      <Icon name="delete" size="16px" color="grey" />
    </ConfirmationModalView>
  );
};

const EventsView = (props: EventsGeneratedProps): JSX.Element => (
  <Flex flexDir={['column', 'column', 'row']} flex={1}>
    <Flex p={5} pt={10} pb={2} direction="column">
      <InputGroup size="md">
        <Input
          type={'text'}
          placeholder="Search Event"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            e.preventDefault();
            props.handleSearch(e.target.value);
          }}
        />
        <InputRightElement>
          <Icon name="search" color="grey" />
        </InputRightElement>
      </InputGroup>
      <Stack p={2}>
        <Flex onClick={props.handleToggle} cursor="pointer" maxW={70}>
          <Text fontSize={12}>FILTERS</Text>
          <Icon
            ml={2}
            name={props.showFilters ? 'chevron-up' : 'chevron-down'}
          />
        </Flex>
        <Collapse isOpen={props.showFilters}>
          <Flex
            flexDir={['row', 'row', 'column']}
            justify="space-between"
            mb={[0, 0, 3]}
          >
            <FormLabel>Upcoming Events</FormLabel>
            <Switch
              size="sm"
              maxW={50}
              onChange={() => props.onFilter('upcoming')}
              isChecked={props.filters.upcoming}
            />
          </Flex>
          <Flex
            flexDir={['row', 'row', 'column']}
            justify="space-between"
            mb={[0, 0, 3]}
          >
            <FormLabel>OnGoing Events</FormLabel>
            <Switch
              size="sm"
              maxW={50}
              onChange={() => props.onFilter('ongoing')}
              isChecked={props.filters.ongoing}
            />
          </Flex>
          <Flex flexDir={['row', 'row', 'column']} justify="space-between">
            <FormLabel>Expired Events</FormLabel>
            <Switch
              size="sm"
              maxW={50}
              onChange={() => props.onFilter('expired')}
              isChecked={props.filters.expired}
            />
          </Flex>
        </Collapse>
      </Stack>
    </Flex>
    <Flex flex={1} direction="column" bg="#f1f1f1" p={5} pt={[5, 5, 10]}>
      <Flex direction="row" alignItems="flex-end" justify="space-between">
        <Text fontSize="3xl">Events-{props.data.length}</Text>
        <Button
          bg="purple.900"
          color="white"
          size="sm"
          mb={1}
          onClick={props.onNewClick}
        >
          New
        </Button>
      </Flex>
      <Box
        fontSize={[10, 10, 12]}
        borderRadius="2px"
        bg="#fff"
        p={2}
        mt={2}
        overflowX="auto"
        overflowY="auto"
        maxH="76vh"
        shadow="sm"
      >
        <Box minW="750px">
          <Flex
            direction="row"
            ml={10}
            mt={2}
            mb={2}
            mr="4.5rem"
            fontWeight="bold"
          >
            <Text flex={0.2}>ID</Text>
            <Flex flex={1} direction="row" alignItems="center" mr={2}>
              <Text>NAME</Text>
              <Icon
                name="up-down"
                size="8px"
                ml={1}
                cursor="pointer"
                onClick={() => props.sortBy('name')}
              />
            </Flex>
            <Flex flex={0.6} direction="row" alignItems="center" mr={2}>
              <Text>ORGANIZATION</Text>
              <Icon name="up-down" size="8px" ml={1} cursor="pointer" />
            </Flex>
            <Flex flex={0.4} direction="row" alignItems="center">
              <Text>START</Text>
              <Icon
                name="up-down"
                size="8px"
                ml={1}
                cursor="pointer"
                onClick={() => props.sortBy('start')}
              />
            </Flex>
            <Flex flex={0.4} direction="row" alignItems="center">
              <Text>END</Text>
              <Icon
                name="up-down"
                size="8px"
                ml={1}
                cursor="pointer"
                onClick={() => props.sortBy('end')}
              />
            </Flex>
            <Text flex={0.3}>STATUS</Text>
            <Text flex={0.4} textAlign="center">
              PUBLISHED
            </Text>
          </Flex>
        </Box>
        {props.isLoading || props.isDeleting ? (
          <ListLoadingIndicator />
        ) : (
          props.data.map((item) => (
            <Box
              key={item.id}
              flexDirection="row"
              display="flex"
              py={3}
              px={2}
              mb={1}
              borderWidth="1px"
              borderRadius={3}
              minW="750px"
              cursor="pointer"
              fontWeight="500"
            >
              <Box w="15px" />
              <Flex
                flex={1}
                direction="row"
                onClick={() => props.onEventClick(item.id)}
                color="gray.500"
                ml={4}
                alignItems="center"
              >
                <Text flex={0.2}>{item.id}</Text>
                <Text isTruncated mr={2} flex={1}>
                  {item.name || 'Unnamed Event'}
                </Text>
                <Text isTruncated mr={2} flex={0.6}>
                  org name
                </Text>
                <Text flex={0.4}>
                  {item.start
                    ? moment(item.start)
                        .utc()
                        .tz('America/New_York')
                        .format('YYYY-MM-DD')
                    : 'n\\a'}
                </Text>
                <Text flex={0.4}>
                  {item.end
                    ? moment(item.end)
                        .utc()
                        .tz('America/New_York')
                        .format('YYYY-MM-DD')
                    : 'n\\a'}
                </Text>
                <Box flex={0.3}>{eventStatus(item)}</Box>
                <Stack alignItems="center" flex={0.4}>
                  {item.draft ? (
                    <Badge
                      fontSize="10px"
                      variant="outline"
                      variantColor="orange"
                    >
                      DRAFT
                    </Badge>
                  ) : (
                    <Badge
                      fontSize="10px"
                      variant="outline"
                      variantColor="green"
                    >
                      LIVE
                    </Badge>
                  )}
                </Stack>
              </Flex>
              <Flex direction="row" alignItems="center">
                <Icon
                  name="edit"
                  size="16px"
                  color="grey"
                  onClick={() => props.onEventClick(item.id)}
                />
                <Icon
                  name="copy"
                  size="16px"
                  ml={2}
                  mr={2}
                  color="grey"
                  onClick={(e) => props.onCopyClick(e, item.id)}
                />
                <DeleteIcon
                  onDeleteClick={() => props.onDeleteClick(item.id)}
                />
              </Flex>
            </Box>
          ))
        )}
      </Box>
    </Flex>
  </Flex>
);

export default EventsView;
