import React, { useState } from 'react';

import { Button, Flex, FormLabel, Select, Stack, Text } from '@chakra-ui/core';
import { uploadSponsorImage } from 'api/sponsors';
import FormikInput from 'components/primitives/FormikInput';
import FormikSwitch from 'components/primitives/FormikSwitch';
import MotionModal from 'components/primitives/MotionModal';
import { Form, Formik } from 'formik';
import * as R from 'ramda';
import { Sponsor } from 'types/events';

import FormikImageUpload from '../FormikImageUpload';
import { SponsorFormProps } from './SponsorForm.props';

const SponsorForm = (props: SponsorFormProps): JSX.Element => {
  const allSponsors = props.allSponsors;
  const [deleteLoading, setDeleteLoading] = useState(false);

  const onDelete = async () => {
    if (props.data && props.onDelete) {
      setDeleteLoading(true);
      await props.onDelete({
        challenge_id: props?.data?.challenge_id,
        sponsor_id: props?.data?.sponsor_id,
      });
      setDeleteLoading(false);
    }
  };

  return (
    <Formik<Partial<Sponsor>>
      initialValues={props.data || {}}
      onSubmit={async (values, formik) => {
        formik.setSubmitting(true);
        await props.onSubmit(values);
        formik.setSubmitting(false);
        props.closeModal();
      }}
    >
      {({ isSubmitting, values, initialValues, setFieldValue }) => (
        <Form style={{ width: '100%' }}>
          <MotionModal
            title={`${props.data ? 'Update' : 'Create'} Sponsor`}
            isModal={props.isModal}
            closeModal={props.closeModal}
            w={['98%', '400px', '500px']}
            h={['500px', '680px']}
          >
            <Stack spacing={2} w="100%" mx={3}>
              <Flex direction="column">
                <FormikImageUpload
                  width={100}
                  height={100}
                  name="logo"
                  s3Key="sponsors"
                  uploadHandler={uploadSponsorImage}
                  flexProps={{ alignSelf: 'center' }}
                />
                <Text fontSize="12px" color="#9a9898" textAlign="center">
                  image dimensions goes here
                </Text>
              </Flex>
              <FormikInput
                name="name"
                label="SPONSOR NAME"
                isReadOnly={props.data ? true : false}
              />
              <FormikInput
                name="website"
                label="SPONSOR WEBSITE"
                isReadOnly={props.data ? true : false}
              />
              <Flex direction="column" mt={2}>
                <FormLabel
                  htmlFor="top banner"
                  fontWeight="600"
                  fontSize="12px"
                  color="grey"
                >
                  TOP BANNER
                </FormLabel>
                <FormikSwitch name="top_banner" size="md" w="40px" />
              </Flex>
              <Flex flex={1} direction="column" justifyContent="flex-end">
                <FormLabel htmlFor="banner ad" color="grey" fontSize="12px">
                  BANNER AD
                </FormLabel>
                <FormikImageUpload
                  preview={false}
                  name="banner_ad"
                  s3Key="sponsors"
                  uploadHandler={uploadSponsorImage}
                />
                <Text fontSize="12px" color="#9a9898">
                  image dimensions goes here
                </Text>
              </Flex>
              <FormikInput label="BANNER AD LINK" name="banner_link" />
              <Stack isInline align="flex-end">
                <Flex direction="column" flex={1}>
                  <FormLabel
                    htmlFor="sponsor_area"
                    color="grey"
                    fontSize="12px"
                  >
                    SPONSOR AREA
                  </FormLabel>
                  <Select
                    value={values.sponsor_area}
                    onChange={(e) => {
                      setFieldValue(
                        'sponsor_area',
                        parseInt(e.target.value || '0')
                      );
                    }}
                  >
                    {[
                      {
                        name: '1 - Banner',
                        value: 1,
                      },
                      {
                        name: '2 - Card',
                        value: 2,
                      },
                      {
                        name: '3 - Leaderboard',
                        value: 3,
                      },
                    ].map((item, index) => (
                      <option key={`${item}-${index}-key`} value={item.value}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </Flex>
                <Flex />
                <Flex direction="column" flex={1}>
                  <FormLabel htmlFor="order_by" color="grey" fontSize="12px">
                    ORDER BY
                  </FormLabel>
                  <Select
                    value={values.order_by}
                    onChange={(e) => {
                      setFieldValue(
                        'order_by',
                        parseInt(e.target.value || '0')
                      );
                    }}
                  >
                    {Array.from({
                      length: props.data
                        ? allSponsors?.length || 1
                        : (allSponsors?.length || 0) + 1,
                    })
                      .map((_, i) => i + 1)
                      .map((item, index) => (
                        <option key={`${item}-${index}-key`} value={item}>
                          {item}
                        </option>
                      ))}
                  </Select>
                </Flex>
              </Stack>
              <Stack isInline mt={3}>
                <Button
                  type="button"
                  size="md"
                  bg="red.500"
                  color="white"
                  flex={1}
                  onClick={onDelete}
                  isLoading={deleteLoading}
                  isDisabled={props.data ? false : true}
                >
                  Delete
                </Button>
                {props.data ? (
                  <Button
                    type="submit"
                    size="md"
                    bg="orange.500"
                    color="white"
                    flex={1}
                    isLoading={isSubmitting}
                    isDisabled={R.equals(initialValues, values)}
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    size="md"
                    bg="purple.500"
                    color="white"
                    flex={1}
                    isLoading={isSubmitting}
                    isDisabled={values.name ? false : true}
                  >
                    Create
                  </Button>
                )}
              </Stack>
            </Stack>
          </MotionModal>
        </Form>
      )}
    </Formik>
  );
};

export default SponsorForm;
