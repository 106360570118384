import React from 'react';

import { Flex, Text } from '@chakra-ui/core';
import CharityForm from 'components/modules/CharityForm';

import { CharityCreateGeneratedProps } from './CharityCreate.props';

const CharityCreateView = (props: CharityCreateGeneratedProps): JSX.Element => (
  <Flex flex={1} direction="column" alignItems="center" pb="200px">
    <Flex maxWidth="1300px" w="100%" p={4} direction="column">
      <Text fontSize="30px" fontWeight="600" my={5} color="#4f4f4f">
        Create a New Charity
      </Text>
      <CharityForm onSubmit={props.onSubmit} />
    </Flex>
  </Flex>
);

export default CharityCreateView;
