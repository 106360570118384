import React from 'react';

import {
  FormControl,
  FormLabel,
  Text,
  FormHelperText,
  Input,
} from '@chakra-ui/core';
import { useField } from 'formik';

import { FormikInputProps } from './FormikInput.props';

const FormikInputView = ({
  name,
  label,
  helperText,
  error,
  ...props
}: FormikInputProps): JSX.Element => {
  const [{ value }, meta, field] = useField(name);
  return (
    <FormControl mt={4} flex={1}>
      {label && (
        <FormLabel htmlFor={name} fontSize="12px" fontWeight="600" color="grey">
          {label}
        </FormLabel>
      )}
      <Input
        {...props}
        type={name}
        id={name}
        value={value || ''}
        isInvalid={Boolean((error || meta.error) && meta.touched)}
        aria-describedby={`${name}-helper-text`}
        onClick={() => field.setTouched(true)}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          field.setValue(e.target.value)
        }
      />
      {helperText && (
        <FormHelperText fontSize="12px" w="90%">
          {helperText}
        </FormHelperText>
      )}
      {(error || meta.error) && meta.touched && (
        <Text color="red.500" fontSize="sm">
          {error || meta.error}
        </Text>
      )}
    </FormControl>
  );
};

export default FormikInputView;
