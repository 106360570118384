import React from 'react';

import { CloseButton, Flex, Stack, Text } from '@chakra-ui/core';
import { motion } from 'framer-motion';

import { ModalProps } from './MotionModal.props';

const variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const MotionStack = motion.custom(Stack);

const MotionModal = (props: ModalProps) =>
  props.isModal ? (
    <MotionStack
      variants={variants}
      initial="hidden"
      animate="visible"
      exit="hidden"
      bg="rgba(0,0,0,0.5)"
      position="fixed"
      left={0}
      top={0}
      zIndex={10}
      w="100%"
      h="100%"
      flex={1}
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        {...props}
        bg="white"
        borderRadius="10px"
        shadow="lg"
        zIndex={10}
        p={5}
      >
        <Flex w="100%" justifyContent="space-between" alignItems="center">
          <Text
            fontSize="20px"
            fontFamily="Helvetica Neue"
            {...props.titleProps}
          >
            {props.title}
          </Text>
          <CloseButton onClick={() => props.closeModal()} />
        </Flex>
        <Flex overflowY="auto">{props.children}</Flex>
      </Stack>
    </MotionStack>
  ) : null;

export default MotionModal;
