import React from 'react';

import { Flex } from '@chakra-ui/core';
import NotificationsTable from 'components/modules/NotificationsTable';

import { NotificationsGeneratedProps } from './Notifications.props';

const NotificationsView = (props: NotificationsGeneratedProps): JSX.Element => (
  <Flex flex={1} alignItems="center" direction="column">
    <NotificationsTable />
  </Flex>
);

export default NotificationsView;
