import { useEffect, useState } from 'react';

const useResponsive = (): { isMobile: boolean } => {
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < 800 ? true : false
  );

  const handleResize = () => {
    if (window.innerWidth < 800) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { isMobile };
};

export default useResponsive;
