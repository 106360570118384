import React from 'react';

import { Box, Button, Flex, Stack, Text } from '@chakra-ui/core';
import FormikInput from 'components/primitives/FormikInput';
import FormikTextArea from 'components/primitives/FormikTextArea';
import { Form, Formik } from 'formik';
import * as R from 'ramda';
import { CharityDetails } from 'types/charities';

import { CharityFormProps } from './CharityForm.props';
import { initialValues, validationSchema } from './CharityForm.utils';

const CharityFormView = (props: CharityFormProps): JSX.Element => {
	return (
		<Formik<CharityDetails>
			initialValues={props.data ? props.data : initialValues}
			onSubmit={async (values, formik) => {
				formik.setSubmitting(true);
				await props.onSubmit(values);
				formik.setSubmitting(false);
			}}
			validationSchema={validationSchema}
			enableReinitialize
		>
			{({ isSubmitting, values, initialValues }) => (
				<Form>
					<Flex flex={1} direction="column">
						<Flex flex={1} direction={['column-reverse', 'row']}>
							<Stack maxW="450px" w="100%" alignSelf="flex-start">
								<FormikInput name="name" size="lg" label="CHARITY NAME*" />
								<FormikInput name="website" size="lg" label="CHARITY WEBSITE*" />
								<FormikInput
									name="logo"
									size="lg"
									label="LOGO URL*"
									helperText="please provide a link to your organisation's logo"
								/>
							</Stack>
							<Flex pl={[0, 16]} maxW="700px" w="100%" direction="column">
								<FormikTextArea
									name="mission_statement"
									label="MISSION STATEMENT*"
									h="145px"
								/>
							</Flex>
						</Flex>
						<Flex direction="column" flex={1} mt={10}>
							<Text mb={5} fontSize="25px" fontWeight="600" color="#4f4f4f">
								Additional Details
							</Text>
							<Flex maxW="800px" direction={['column', 'row']}>
								<Box w="100%">
									<FormikInput name="official_name" label="OFFICIAL NAME*" />
								</Box>
								<Box w="50px" />
								<Box w="100%">
									<FormikInput name="contact_email" label="CONTACT EMAIL*" />
								</Box>
							</Flex>
							<Flex maxW="800px" direction={['column', 'row']}>
								<Box w="100%">
									<FormikInput
										name="charity_type"
										label="CHARITY TYPE*"
										helperText="The type of work your charity organization is focused on"
									/>
								</Box>
								<Box w="50px" />
								<Box w="100%">
									<FormikInput
										name="ein"
										label="EIN*"
										helperText="Your organization's employer identification number"
									/>
								</Box>
							</Flex>
							<Flex my={5} direction="column" maxW="450px">
								<FormikTextArea
									name="street_address"
									label="STREET ADDRESS*"
									h="100px"
								/>
							</Flex>
							<Flex maxW="430px">
								<Box w="100%">
									<FormikInput name="city" label="CITY*" />
								</Box>
								<Box w="50px" />
								<Box w="100%">
									<FormikInput name="state" label="STATE*" />
								</Box>
							</Flex>
							<Flex maxW="430px">
								<Box w="100%">
									<FormikInput name="country" label="COUNTRY*" />
								</Box>
								<Box w="50px" />
								<Box w="100%">
									<FormikInput name="zip" label="ZIP*" />
								</Box>
							</Flex>
						</Flex>
						<Button
							maxW="430px"
							my={10}
							bg={props.data ? 'yellow.500' : '#45C2CC'}
							color="white"
							type="submit"
							isLoading={isSubmitting}
							isDisabled={R.equals(initialValues, values)}
							fontWeight="bold"
						>
							{props.data ? 'UPDATE DETAILS' : 'SAVE'}
						</Button>
					</Flex>
				</Form>
			)}
		</Formik>
	);
};

export default CharityFormView;