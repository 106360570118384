import React from 'react';

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/core';

import { ConfirmationModalProps } from './ConfirmationModal.props';

const ConfirmationModalView = (props: ConfirmationModalProps): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <div onClick={onOpen}>
      {props.children}
      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Admin</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb="1rem">{props.message}</Text>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              No
            </Button>
            <Button
              isLoading={props.isLoading}
              onClick={async () => {
                await props.onConfirmClick();
                onClose();
              }}
              variantColor="red"
            >
              Yes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ConfirmationModalView;
