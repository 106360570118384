import React, { useEffect, useState } from 'react';

import { useToast } from '@chakra-ui/core';
import { fetchAll, fetchEventDetails } from 'api/events';
import {
  createNotification,
  getNotificationDetails,
  sendTestNotification,
  updateNotification,
} from 'api/notification';
import { useMutation } from 'react-query';
import { EventItem } from 'types/events';
import { Notification } from 'types/notification';
import { Team } from 'types/team';

import { NotificationFormProps } from './NotificationForm.props';
import NotificationFormView from './NotificationForm.view';

const NotificationFormContainer = (
  props: NotificationFormProps
): JSX.Element => {
  const toast = useToast();
  const [events, setEvents] = useState<EventItem[]>();
  const [teams, setTeams] = useState<Team[]>();
  const [data, setData] = useState<Notification>();
  const [selectedEvent, setSelectedEvent] = useState(0);
  const setEvent = (value: number) => setSelectedEvent(value);

  const [fetchAllEvents] = useMutation(fetchAll);
  const [fetchEventTeams, { isLoading: isTeamsLoading }] =
    useMutation(fetchEventDetails);

  const [createNotificationSubmit] = useMutation(createNotification, {
    onSuccess: () => {
      toast({
        title: 'Success',
        description: 'Notification created successfully',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });

      props.onSuccess();
    },
  });

  const [fetchNotificationDetails, { isLoading: isDetailsLoading }] =
    useMutation(getNotificationDetails);

  const [updateNotificationSubmit] = useMutation(updateNotification, {
    onSuccess: () => {
      toast({
        title: 'Success',
        description: 'Notification Updated successfully',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      props.onSuccess();
    },
  });

  const [testNotificationSubmit] = useMutation(sendTestNotification, {
    onSuccess: () => {
      toast({
        title: 'Success',
        description: 'Notification sent successfully',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });

      props.onSuccess();
    },
  });

  //get event details
  useEffect(() => {
    if (props.notificationId) {
      fetchNotificationDetails(props.notificationId).then((values) => {
        setData(values);
        setSelectedEvent(values?.challenge_id || 0);
      });
    }
  }, [fetchNotificationDetails, props.notificationId]);

  //get all events
  useEffect(() => {
    if (!props.isEvent) {
      fetchAllEvents().then((values) => setEvents(values));
    }
  }, [fetchAllEvents, props.isEvent]);

  //get teams (from props.challenge_id)
  useEffect(() => {
    if (props.challenge_id) {
      fetchEventTeams(props.challenge_id).then((values) => {
        setTeams(values?.teams);
      });
    }
  }, [props.challenge_id, fetchEventTeams]);

  //get teams (for selectedEvent)
  useEffect(() => {
    if (selectedEvent !== 0) {
      fetchEventTeams(selectedEvent).then((values) => {
        setTeams(values?.teams);
      });
    }
  }, [selectedEvent, fetchEventTeams]);

  // send test notification
  const onTest = async (values: any) => {
    await testNotificationSubmit({
      team_id: values.team_id,
      challenge_id: values.challenge_id,
      body: values.body,
      title: values.title,
    });
  };

  // create-notification submit
  const onSubmit = async (values: Partial<Notification>) => {
    if (props.isEvent && props.challenge_id) {
      await createNotificationSubmit({
        ...values,
        challenge_id: props?.challenge_id,
      });
    } else {
      await createNotificationSubmit(values);
    }
    props.refetch();
  };

  // update-notification
  const onUpdate = async (values: Partial<Notification>) => {
    if (values) await updateNotificationSubmit(values);
    props.refetch();
  };

  return (
    <NotificationFormView
      isEvent={props.isEvent}
      onSubmit={onSubmit}
      teams={teams}
      events={events}
      selectedEvent={selectedEvent}
      setEvent={setEvent}
      isTeamsLoading={isTeamsLoading}
      onTest={onTest}
      onUpdate={onUpdate}
      data={data}
      isDetailsLoading={isDetailsLoading}
      isModal={props.isModal}
      closeModal={props.closeModal}
      challenge_id={props.challenge_id}
    />
  );
};

export default NotificationFormContainer;
