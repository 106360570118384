import React from 'react';

import { FormControl, FormLabel, Input, Text } from '@chakra-ui/core';
import { useField, useFormikContext } from 'formik';
import moment, { Moment } from 'moment-timezone';
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

import { FormikDateTimePickerProps } from './FormikDateTimePicker.props';

const FormikDateTimePickerView = ({
  name,
  error,
  label,
  helper,
  ...props
}: FormikDateTimePickerProps): JSX.Element => {
  const [{ value = new Date() }, meta] = useField(name);
  const formik = useFormikContext();

  const yesterday = moment(new Date()).subtract(1, 'day');
  const disablePastDt = (current: Moment) => {
    return current.isAfter(yesterday);
  };

  return (
    <FormControl mt={2}>
      {label && (
        <FormLabel htmlFor={name} fontSize="12px" fontWeight="600" color="grey">
          {label}
        </FormLabel>
      )}
      <DateTime
        timeFormat="hh:mm a"
        isValidDate={disablePastDt}
        dateFormat="YYYY-MM-DD"
        initialValue={moment(value === null ? new Date() : value)
          .utc()
          .tz('America/New_York')}
        value={moment(value === null ? new Date() : value)
          .utc()
          .tz('America/New_York')}
        renderInput={(_, openCal) => {
          return (
            <Input
              onClick={() => openCal()}
              isReadOnly
              value={
                value === null
                  ? undefined
                  : moment(value)
                      .utc()
                      .tz('America/New_York')
                      .format('YYYY-MM-DD HH:mm:ss')
              }
            />
          );
        }}
        onChange={(val: string | Moment) => {
          if (typeof val === 'string') {
            formik.setFieldValue(name, new Date(val).toISOString());
          } else if (typeof val === 'object') {
            formik.setFieldValue(name, val.toISOString());
          }
        }}
        {...props}
      />
      {helper && (
        <Text fontSize="12px" color="grey">
          {helper}
        </Text>
      )}
      {(error || meta.error) && (
        <Text color="red.500" fontSize="sm">
          {error || meta.error}
        </Text>
      )}
    </FormControl>
  );
};

export default FormikDateTimePickerView;
