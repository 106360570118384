import React, { useState } from 'react';

import { Box, Button, Flex, Icon, Stack, Text } from '@chakra-ui/core';
import ConfirmationModal from 'components/primitives/ConfirmationModal';
import FormikInput from 'components/primitives/FormikInput';
import { Form, Formik } from 'formik';
import * as R from 'ramda';
import { MerchItem } from 'types/merch';

import { MerchGeneratedProps } from './Merch.props';

const MerchView = (props: MerchGeneratedProps): JSX.Element => {
  const [isEditing, setIsEditing] = useState(false);
  const [selectedMerch, setSelectedMerch] = useState<MerchItem | null>(null);

  return (
    <Flex flex={1} direction="column" alignItems="center" px={4}>
      <Flex flex={1} w="100%" maxW="1300px" direction="column" py={8} mb={50}>
        <Text fontSize="26px" fontWeight="600" color="#4f4f4f" mb={4}>
          {isEditing ? 'Update' : 'Create New'} Merch Catalogue Item
        </Text>
        <Formik<MerchItem>
          initialValues={
            selectedMerch ? selectedMerch : { label: '', size: '' }
          }
          enableReinitialize
          onSubmit={async (values, formik) => {
            formik.setSubmitting(true);
            if (isEditing) {
              await props.onUpdate(values);
            } else {
              await props.onCreate(values);
            }
            formik.setSubmitting(false);
            formik.resetForm();
            setIsEditing(false);
            setSelectedMerch(null);
          }}
        >
          {({ isSubmitting, initialValues, values }) => (
            <Form>
              <Flex flex={1} maxW="600px" direction="column">
                <Stack isInline spacing={4}>
                  <FormikInput name="label" label="LABEL*" />
                  <Box w="20px" />
                  <FormikInput
                    name="size"
                    label="SIZE*"
                    helperText="eg: XS, S, L, XL, 2XL, 3Xl"
                  />
                </Stack>
                <Stack isInline spacing={4} mt={4}>
                  <Button
                    type="submit"
                    w="150px"
                    alignSelf={['flex-start', 'flex-end']}
                    fontSize="14px"
                    bg={isEditing ? 'yellow.500' : 'purple.800'}
                    color="white"
                    isLoading={isSubmitting}
                    isDisabled={R.equals(initialValues, values)}
                  >
                    {isEditing ? 'UPDATE' : 'CREATE'}
                  </Button>
                  {isEditing && (
                    <Button
                      w="150px"
                      alignSelf={['flex-start', 'flex-end']}
                      fontSize="14px"
                      bg="red.300"
                      color="white"
                      onClick={() => {
                        setIsEditing(false);
                        setSelectedMerch(null);
                      }}
                    >
                      CLEAR
                    </Button>
                  )}
                </Stack>
              </Flex>
            </Form>
          )}
        </Formik>
        <Flex flex={1} direction="column" py={4} maxW="650px">
          <Text fontSize="18px" fontWeight="600" color="#4f4f4f" my={4}>
            Merch Catalogue
          </Text>
          <Flex
            fontSize="12px"
            fontWeight="bold"
            color="grey"
            borderWidth={1}
            py={1}
            px={2}
          >
            <Text flex={0.5}>ID</Text>
            <Text flex={2}>LABEL</Text>
            <Text flex={0.5}>SIZE</Text>
            <Box w="46px" />
          </Flex>
          {!props.isFetching ? (
            props.merchCatalogue.map((item, index) => (
              <Flex
                key={`${item.id}-${index}`}
                fontSize="14px"
                fontWeight="600"
                my={2}
                mt={2}
                px={2}
                alignItems="center"
              >
                <Text flex={0.5}>{item.id}</Text>
                <Text flex={2} isTruncated>
                  {item.label || 'null'}
                </Text>
                <Text flex={0.5}>{item.size || 'null'}</Text>
                <Icon
                  name="edit"
                  mr={4}
                  onClick={() => {
                    setIsEditing(true);
                    setSelectedMerch(item);
                    window.scroll({ behavior: 'smooth', top: 0 });
                  }}
                />
                <ConfirmationModal
                  message="Are you sure you want to permanently delete this merch catalogue?"
                  onConfirmClick={async () => await props.onDelete(item?.id)}
                  isLoading={props.isDeleting}
                >
                  <Icon name="delete" size="14px" mb={1} />
                </ConfirmationModal>
              </Flex>
            ))
          ) : (
            <Text p={2}>Loading...</Text>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default MerchView;
