import { createEvent } from 'effector';
import withStorage from 'effector-storage/sync';
import { Session } from 'types/session';

import { SessionDomain } from './domain';
import { loginEvent, logoutEvent } from './events';

const initialState: Session = {};

const createSessionStore = withStorage(SessionDomain.store, createEvent);

export const SessionStore = createSessionStore(initialState, {
  key: 'sessionStore',
})
  .on(loginEvent, (_state, res) => res)
  .on(logoutEvent, () => ({}));
