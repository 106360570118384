import React, { useState } from 'react';

import { Button, Flex, Spinner, Stack } from '@chakra-ui/core';
import LoadingIndicator from 'components/common/LoadingIndicator';
import EmojiPicker from 'components/primitives/EmojiPicker';
import FormikDateTimePicker from 'components/primitives/FormikDateTimePicker';
import FormikInputView from 'components/primitives/FormikInput';
import FormikSelectInputView from 'components/primitives/FormikSelectInput';
import FormikTextArea from 'components/primitives/FormikTextArea';
import MotionModal from 'components/primitives/MotionModal';
import {
  Form,
  Formik,
  FormikHelpers,
  FormikValues,
  useFormikContext,
} from 'formik';
import moment from 'moment';
import * as R from 'ramda';

import {
  NotificationFormGeneratedProps,
  TagButtonProps,
} from './NotificationForm.props';
import { setDateTime, getInitialValues } from './NotificationForm.utils';

const TagButton = ({
  name,
  inputFocus,
  value,
  isDisabled,
  color,
}: TagButtonProps) => {
  const { values: formikValues, setFieldValue } = useFormikContext<any>();

  const valuesMap: Record<string, string> = {
    title: formikValues.title,
    subject: formikValues.subject,
    body: formikValues.body,
  };

  return (
    <Button
      variantColor={color}
      size="sm"
      isDisabled={isDisabled}
      mr={4}
      onClick={() => {
        const text = valuesMap[inputFocus];

        setFieldValue(inputFocus, `${text}${value}`);
      }}
    >
      {name}
    </Button>
  );
};

const NotificationFormView = (
  props: NotificationFormGeneratedProps
): JSX.Element => {
  const [testLoading, setTestLoading] = useState(false);

  const initialValues = getInitialValues(props);

  const onSubmit = async (values: FormikValues, formik: FormikHelpers<any>) => {
    const dateTime = setDateTime(values);
    const formattedDateTime = dateTime
      ? moment(dateTime).utc().format('YYYY-MM-DD HH:mm:ss')
      : null;

    const formattedValues = {
      title: values.title,
      body: values.body,
      subject: values?.subject,
      send_time: formattedDateTime,
      challenge_id: values.challenge_id || null,
      team_id: values.team_id || null,
    };

    formik.setSubmitting(true);
    if (props.data) {
      await props.onUpdate({ ...formattedValues, id: props.data.id });
    } else {
      await props.onSubmit(formattedValues);
    }
    formik.setSubmitting(false);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ initialValues, values, isSubmitting, setFieldValue }) => (
        <Form>
          <MotionModal
            title={props.data ? 'Update Notification' : 'Create Notification'}
            isModal={props.isModal}
            closeModal={props.closeModal}
            w={['98%', '1000px']}
            h="650px"
          >
            <Flex flex={1} direction="column" px={1}>
              {props.isDetailsLoading ? (
                <LoadingIndicator />
              ) : (
                <Flex flex={1} direction="column">
                  <Flex flex={1} direction={['column', 'column', 'row']}>
                    <Flex flex={1} direction="column">
                      <FormikSelectInputView
                        maxW="250px"
                        name="send_to"
                        label="SEND TO:"
                        placeholder="Select an option"
                      >
                        {!props.isEvent && (
                          <option value="All Kilter Users">
                            All Kilter Users
                          </option>
                        )}
                        <option value="Event">Event</option>
                        {props.isEvent && <option value="Team">Team</option>}
                      </FormikSelectInputView>
                    </Flex>
                  </Flex>
                  {values.send_to !== 'All Kilter Users' && (
                    <Flex
                      flex={1}
                      direction={['column', 'column', 'row']}
                      mt={5}
                    >
                      {!props.isEvent && (
                        <Flex flex={1} direction="column">
                          <FormikSelectInputView
                            maxW="250px"
                            name="challenge_id"
                            label="EVENTS"
                            placeholder="Select an option"
                            setEvent={props.setEvent}
                          >
                            {props.events?.length &&
                              props.events.map((event, index) => (
                                <option key={`${index}`} value={event.id}>
                                  {event.name}
                                </option>
                              ))}
                          </FormikSelectInputView>
                        </Flex>
                      )}
                      {props.isEvent && values.send_to === 'Event' ? null : (
                        <Flex
                          flex={1}
                          direction={props.isTeamsLoading ? 'row' : 'column'}
                        >
                          <FormikSelectInputView
                            maxW="250px"
                            name="team_id"
                            label="TEAMS:"
                            placeholder="Select an option"
                          >
                            {props.teams?.length &&
                              props.teams.map((team, index) => (
                                <option key={`${index}`} value={team.id}>
                                  {team.name}
                                </option>
                              ))}
                          </FormikSelectInputView>
                          {props.isTeamsLoading && (
                            <Spinner alignSelf="center" ml={5} mt={5} />
                          )}
                        </Flex>
                      )}
                      <Flex flex={1} direction="column" />
                    </Flex>
                  )}
                  <Flex flex={1} direction={['column', 'column', 'row']} mt={4}>
                    <Flex flex={1} direction="column">
                      <FormikSelectInputView
                        name="schedule"
                        label="TYPE"
                        maxW="250px"
                        placeholder="Select an option"
                      >
                        <option value="Instant">Instant</option>
                        <option value="Scheduled">Scheduled</option>
                        <option value="Draft">Draft</option>
                      </FormikSelectInputView>
                    </Flex>
                    {values.schedule === 'Scheduled' && (
                      <Flex flex={1} direction={['column', 'column', 'row']}>
                        <Flex flex={1} direction="column">
                          <Stack maxW="250px" spacing={0} pt={0}>
                            <FormikDateTimePicker
                              name="send_time"
                              label="SCHEDULE: (Date/Time) ET"
                            />
                          </Stack>
                        </Flex>
                      </Flex>
                    )}
                    <Flex flex={1} direction="column" />
                  </Flex>
                  <Flex flex={1} mt={6} direction="column">
                    <FormikInputView
                      name="title"
                      label="TITLE"
                      maxW={['100%', '100%', '50%']}
                      helperText={`${values.title.length} out of 63 characters`}
                      maxLength={63}
                    />
                    <EmojiPicker
                      stackProps={{
                        flex: 1,
                        mt: 2,
                      }}
                      onSelect={(emoji) => {
                        const text = values.title;
                        //@ts-ignore
                        setFieldValue('title', `${text}${emoji.native}`);
                      }}
                    />
                    <Stack isInline my={2}>
                      <TagButton
                        name="Team Name"
                        value="{{team_name}}"
                        inputFocus="title"
                        color="green"
                        isDisabled={values.team_id === (null || undefined)}
                      />
                      <TagButton
                        name="Event Name"
                        value="{{event_name}}"
                        inputFocus="title"
                        color="purple"
                        isDisabled={
                          props.isEvent
                            ? false
                            : values.challenge_id === (null || undefined)
                        }
                      />
                      <TagButton
                        name="Participant First Name"
                        value="{{user_name}}"
                        inputFocus="title"
                        color="blue"
                      />
                      <TagButton
                        name="Charity Name"
                        value="{{charity_name}}"
                        inputFocus="title"
                        color="red"
                        isDisabled={
                          props.isEvent
                            ? false
                            : values.challenge_id === (null || undefined)
                        }
                      />
                    </Stack>
                  </Flex>
                  <Flex flex={1} mt={6} direction="column">
                    <FormikInputView
                      name="subject"
                      label="SUBJECT"
                      maxW={['100%', '100%', '50%']}
                      helperText={`${values.subject.length} out of 255 characters`}
                      maxLength={255}
                    />
                    <EmojiPicker
                      stackProps={{
                        flex: 1,
                        mt: 2,
                      }}
                      onSelect={(emoji) => {
                        const text = values.subject;
                        //@ts-ignore
                        setFieldValue('subject', `${text}${emoji.native}`);
                      }}
                    />
                    <Stack isInline my={2}>
                      <TagButton
                        name="Team Name"
                        value="{{team_name}}"
                        inputFocus="subject"
                        color="green"
                        isDisabled={values.team_id === (null || undefined)}
                      />
                      <TagButton
                        name="Event Name"
                        value="{{event_name}}"
                        inputFocus="subject"
                        color="purple"
                        isDisabled={
                          props.isEvent
                            ? false
                            : values.challenge_id === (null || undefined)
                        }
                      />
                      <TagButton
                        name="Participant First Name"
                        value="{{user_name}}"
                        inputFocus="subject"
                        color="blue"
                      />
                      <TagButton
                        name="Charity Name"
                        value="{{charity_name}}"
                        inputFocus="subject"
                        color="red"
                        isDisabled={
                          props.isEvent
                            ? false
                            : values.challenge_id === (null || undefined)
                        }
                      />
                    </Stack>
                  </Flex>
                  <Flex flex={1} direction="column" mt={6}>
                    <Flex flex={1} direction="column">
                      <FormikTextArea
                        name="body"
                        h="150px"
                        maxW={['100%', '100%', '70%']}
                        label="BODY"
                        maxLength={500}
                        helperText={`${values.body.length} out of 500 characters`}
                      />
                    </Flex>
                    <EmojiPicker
                      stackProps={{
                        flex: 1,
                        mt: 2,
                      }}
                      onSelect={(emoji) => {
                        const text = values.body;
                        //@ts-ignore
                        setFieldValue('body', `${text}${emoji.native}`);
                      }}
                    />
                    <Stack isInline my={2}>
                      <TagButton
                        name="Team Name"
                        value="{{team_name}}"
                        inputFocus="body"
                        color="green"
                        isDisabled={values.team_id === (null || undefined)}
                      />
                      <TagButton
                        name="Event Name"
                        value="{{event_name}}"
                        inputFocus="body"
                        color="purple"
                        isDisabled={
                          props.isEvent
                            ? false
                            : values.challenge_id === (null || undefined)
                        }
                      />
                      <TagButton
                        name="Participant First Name"
                        value="{{user_name}}"
                        inputFocus="body"
                        color="blue"
                      />
                      <TagButton
                        name="Charity Name"
                        value="{{charity_name}}"
                        inputFocus="body"
                        color="red"
                        isDisabled={
                          props.isEvent
                            ? false
                            : values.challenge_id === (null || undefined)
                        }
                      />
                    </Stack>
                    <>
                      {props.data ? (
                        <Stack maxW="200px" my={10} spacing={4}>
                          <Button
                            type="submit"
                            bg="orange.400"
                            isDisabled={R.equals(initialValues, values)}
                            isLoading={isSubmitting}
                            color="white"
                          >
                            Update
                          </Button>
                        </Stack>
                      ) : (
                        <Stack maxW="200px" my={10} spacing={4}>
                          {!props.isEvent && (
                            <Button
                              type="button"
                              bg="yellow.400"
                              isDisabled={
                                values.title === '' ||
                                values.body === '' ||
                                values.subject === ''
                              }
                              isLoading={testLoading}
                              onClick={async () => {
                                if (props.onTest) {
                                  setTestLoading(true);
                                  await props.onTest(values);
                                  setTestLoading(false);
                                }
                              }}
                            >
                              Send Test
                            </Button>
                          )}
                          <Button
                            type="submit"
                            bg="green.400"
                            isDisabled={
                              values.title === '' ||
                              values.body === '' ||
                              values.subject === ''
                            }
                            isLoading={isSubmitting}
                          >
                            Create
                          </Button>
                        </Stack>
                      )}
                    </>
                  </Flex>
                </Flex>
              )}
            </Flex>
          </MotionModal>
        </Form>
      )}
    </Formik>
  );
};

export default NotificationFormView;
