import api from 'api/instance';
import { Admission } from 'types/events';
import { AdmissionMerch, AdmissionMerchDetails } from 'types/merch';

const URL = `${process.env.REACT_APP_API_URL}/events/admission`;

export const addAdmissionPriceTier = async (
  values: Partial<Admission>
): Promise<any> => {
  const { data } = await api.post(`${URL}/add`, values);
  return data;
};

export const deleteAdmissionPriceTier = async (id: number): Promise<any> => {
  const { data } = await api.get(`${URL}/remove?id=${id}`);
  return data;
};

export const updateTier = async (values: Partial<Admission>): Promise<any> => {
  const { data } = await api.post(`${URL}/update`, values);
  return data;
};

export const getAdmissionMerch = async (
  id: number
): Promise<AdmissionMerchDetails> => {
  const { data } = await api.get<AdmissionMerchDetails>(
    `${URL}/merch/read?admission_id=${id}`
  );
  return data;
};

export const createAdmissionMerch = async (
  values: Omit<AdmissionMerch, 'current_group_name' | 'new_group_name'>
): Promise<any> => {
  const { data } = await api.post(`${URL}/merch/add`, values);
  return data;
};

export const deleteAdmissionMerch = async (
  values: AdmissionMerch
): Promise<any> => {
  const { data } = await api.post(`${URL}/merch/remove`, values);
  return data;
};

export const updateAdmissionMerch = async (
  values: Omit<AdmissionMerch, 'merch_id' | 'merch_selection_group'>
): Promise<any> => {
  const { data } = await api.post(`${URL}/merch/update-group`, values);
  return data;
};
