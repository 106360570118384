import React from 'react';

import { Box, Divider, Flex, Image, Stack, Text } from '@chakra-ui/core';
import { fetchScoringRules } from 'api/scoringRules';
import { useField } from 'formik';
import HtmlParser, {
  convertNodeToElement,
  processNodes,
} from 'react-html-parser';
import { useQuery } from 'react-query';
import Bike from 'res/rules/icon_bike.png';
import Check from 'res/rules/icon_check.png';
import Fitbit from 'res/rules/icon_fitbit.png';
import Fitness from 'res/rules/icon_fitness.png';
import Frisbee from 'res/rules/icon_frisbee.png';
import Garmin from 'res/rules/icon_garmin.png';
import GoogleFit from 'res/rules/icon_gfit.png';
import Heart from 'res/rules/icon_heart.png';
import Run from 'res/rules/icon_run.png';
import Strava from 'res/rules/icon_strava.png';
import Streak from 'res/rules/icon_streak.png';
import Swim from 'res/rules/icon_swim.png';
import Gym from 'res/rules/icon_tip.png';
import Trophy from 'res/rules/icon_trophy.png';
import UnderArmor from 'res/rules/icon_ua.png';
import Walk from 'res/rules/icon_walk.png';

// hardcoded gym check in reference
const GYM_CHECKIN_ID = 1;

const TrackerItem = ({ name, source }: { name: string; source: any }) => {
  return (
    <Stack flex={1} alignItems="center" justifyContent="center">
      <Box
        boxShadow="0px 0px 19px 0px rgba(50, 50, 50, 0.13)"
        borderRadius="2px"
        h="35px"
        w="35px"
      >
        <Image src={source} h="100%" w="100%" objectFit="contain" />
      </Box>
      <Text fontSize="12px">{name}</Text>
    </Stack>
  );
};

const MessageCard = ({
  image,
  header,
  middleText,
  bg,
  color,
}: {
  image: any;
  header: string;
  middleText: string;
  bg: string;
  color: string;
}) => (
  <Flex
    my="2"
    borderRadius="10px"
    bg={bg}
    whiteSpace="normal"
    color={color}
    lineHeight="1.2"
    p={1}
  >
    <Image src={image} maxH="80px" maxW="80px" w="100%" objectFit="contain" />
    <Flex direction="column" flex={1} p={2}>
      <Text fontWeight="bold" fontSize={16}>
        {header.trim()}
      </Text>
      <Text fontSize={15} mt={1}>
        {middleText}
      </Text>
    </Flex>
  </Flex>
);

type ActivityProps = {
  logo?: any;
  name?: string;
  duration?: string;
  points?: string;
};
const Activity = ({ logo, duration, points, name }: ActivityProps) => (
  <Flex
    p={2}
    borderBottomWidth="1px"
    w="100%"
    fontSize="13px"
    alignItems="center"
  >
    <Image src={logo} h="40px" w="40px" mr={2} />
    <Text flex={1}>{name}</Text>
    <Text flex={0.6}>{duration}</Text>
    <Text flex={0.5} textAlign="right">
      {points}
    </Text>
  </Flex>
);

const DynamicRules = ({ challenge_id }: { challenge_id: number }) => {
  const { data, isLoading } = useQuery(`scoring-rules-${challenge_id}`, () =>
    fetchScoringRules(challenge_id)
  );

  return (
    <Flex direction="column" ml={[0, 4]} flex={1} mt={2}>
      <Text fontSize="12px" fontWeight="bold" color="#4f4f4f" mb={1}>
        PREVIEW
      </Text>
      <Flex
        p={2}
        pb={4}
        px="20px"
        borderRadius="5px"
        borderWidth="1px"
        minW="380px"
        maxW={['350px', '380px']}
        h="590px"
        direction="column"
        overflowX="hidden"
        letterSpacing={0.1}
        whiteSpace="pre-wrap"
      >
        {!isLoading ? (
          <>
            <MessageCard
              image={Trophy}
              bg="#2E2C44"
              color="white"
              header={`You can earn ${
                data?.point_limit_per_day
                  ? data.point_limit_per_day
                  : 'Unlimited'
              } 
              points per day`}
              middleText="Learn how to log your activities and start earning points"
            />
            <Flex mt={2} direction="column">
              <Text fontSize={16} fontWeight="bold">
                How it Works
              </Text>
              <Divider borderColor="#4f4f4f" />
              <Text fontSize={15}>
                In this event, earn for your score by completing one of the
                daily activities using one of the below methods.
                {data?.use_custom_scoring
                  ? null
                  : ' this challenge includes 42+ activities, including:'}
              </Text>
            </Flex>
            <Flex
              mt={4}
              bg="white"
              borderRadius="10px"
              boxShadow="0px 0px 19px 0px rgba(50, 50, 50, 0.13)"
              direction="column"
            >
              {data && data.use_custom_scoring ? (
                data.activity_scoring.map((as) => (
                  <React.Fragment key={as.activity_type_id}>
                    <Activity
                      logo={as.activity_type_image}
                      name={as.activity_type_label}
                      duration={`${as.scoring_rules[0].metric_value} ${as.scoring_rules[0].metric_unit}`}
                      points={`${as.scoring_rules[0].point_value} pts.`}
                    />
                  </React.Fragment>
                ))
              ) : (
                <>
                  <Activity
                    logo={Run}
                    name="Walk/Run"
                    duration="2+ miles"
                    points="100 pts."
                  />
                  <Activity
                    logo={Bike}
                    name="Bicycle"
                    duration="5+ miles"
                    points="100 pts."
                  />
                  <Activity
                    logo={Frisbee}
                    name="Active Minutes"
                    duration="30+ min"
                    points="100 pts."
                  />
                  <Activity
                    logo={Walk}
                    name="Steps"
                    duration="5,000+ steps"
                    points="100 pts."
                  />
                  <Activity
                    logo={Swim}
                    name="Swim"
                    duration="500+ m"
                    points="100 pts."
                  />
                  <Activity
                    logo={null}
                    name="Manual Entry"
                    duration="30+ min"
                    points="100 pts."
                  />
                </>
              )}
              {!data?.use_custom_scoring && (
                <Text fontSize="12px" m={3}>
                  For a full list of activities, head over to the manual entry
                </Text>
              )}
            </Flex>
            <Flex mt={4} direction="column">
              {data?.streak_bonus || data?.extra_mile_bonus ? (
                <Text fontSize="16px" fontWeight="bold">
                  More ways to earn:
                </Text>
              ) : null}
              {data?.streak_bonus ? (
                <MessageCard
                  image={Streak}
                  header="Streak Bonus"
                  middleText="For every 5 days in a row that you log an activity, an additional 100
                  points will be added to your score."
                  bg="#2E2C44"
                  color="white"
                />
              ) : null}
              {data?.extra_mile_bonus ? (
                <Flex
                  mt={4}
                  bg="#f1f1f1"
                  borderRadius="10px"
                  boxShadow="0px 0px 19px 0px rgba(50, 50, 50, 0.05)"
                  direction="column"
                >
                  <MessageCard
                    image={Streak}
                    header="Extra Mile Wednesdays"
                    middleText="Earn 25 Bonus points for putting in a little extra effort on your
                    activity!"
                    bg="#f1f1f1"
                    color="black"
                  />
                  <Activity
                    logo={Run}
                    name="Walk/Run"
                    duration="3+ miles"
                    points="125 pts."
                  />
                  <Activity
                    logo={Bike}
                    name="Bicycle"
                    duration="7.5+ miles"
                    points="125 pts."
                  />
                  <Activity
                    logo={Frisbee}
                    name="Active Minutes"
                    duration="60+ min"
                    points="125 pts."
                  />
                  <Activity
                    logo={Walk}
                    name="Steps"
                    duration="7,500+ steps"
                    points="125 pts."
                  />
                  <Activity
                    logo={Swim}
                    name="Swim"
                    duration="750+ m"
                    points="125 pts."
                  />
                  <Text fontSize="12px" m={2}>
                    *Not awarded for Manual Entries
                  </Text>
                </Flex>
              ) : null}
            </Flex>

            <Flex
              direction="column"
              mt={6}
              borderRadius="10px"
              boxShadow="0px 0px 19px 0px rgba(50, 50, 50, 0.1)"
            >
              <MessageCard
                image={Fitness}
                header="Fitness Tracker"
                middleText="Connect your tracking device to automatically sync your activities"
                bg="white"
                color="black"
              />
              <Divider />
              <Flex mt={4} direction="column">
                <Text fontSize="14px" ml={4}>
                  Compatible devices include:
                </Text>
                <Flex mt={2} alignItems="center">
                  <TrackerItem source={GoogleFit} name="Google Fit" />
                  <TrackerItem source={Fitbit} name="Fitbit" />
                  <TrackerItem source={Garmin} name="Garmin" />
                </Flex>
                <Flex mt={2} alignItems="center">
                  <TrackerItem source={Heart} name="Apple Health" />
                  <TrackerItem source={UnderArmor} name="Under Armor" />
                  <TrackerItem source={Strava} name="Strava" />
                </Flex>
              </Flex>
              <Box
                cursor="pointer"
                my={6}
                bg="#2DCCD3"
                fontSize="16px"
                fontWeight="bold"
                w="250px"
                borderRadius="60px"
                p={2}
                alignSelf="center"
              >
                <Text textAlign="center">Connect your Tracker</Text>
              </Box>
            </Flex>
            {(!data?.use_custom_scoring ||
              (data.use_custom_scoring &&
                !!data.activity_scoring.length &&
                data.activity_scoring.find(
                  (as) => as.activity_type_id === GYM_CHECKIN_ID
                ))) && (
              <Flex
                direction="column"
                mt={6}
                borderRadius="10px"
                boxShadow="0px 0px 19px 0px rgba(50, 50, 50, 0.1)"
              >
                <MessageCard
                  image={Gym}
                  header="Gym Check-In"
                  middleText="Add your Gym and spend a 30-minute session there"
                  bg="white"
                  color="black"
                />
                <Divider />
                <Text m={4} whiteSpace="pre-line">
                  Can&apos;t find your fitness center? No worries! Let us Know
                  and we&apos;ll add it on our end.{'\n\n'}Note: You must be
                  within a 0.25 mile range of your gym for the check-in to work.
                </Text>
                <Box
                  cursor="pointer"
                  mb={4}
                  bg="#2DCCD3"
                  fontSize="16px"
                  fontWeight="bold"
                  w="250px"
                  borderRadius="60px"
                  p={2}
                  alignSelf="center"
                >
                  <Text textAlign="center">Check Into your Gym</Text>
                </Box>
              </Flex>
            )}
            {(!data?.use_custom_scoring ||
              (data.use_custom_scoring && data.allow_manual_activity)) && (
              <Flex
                direction="column"
                mt={6}
                borderRadius="10px"
                boxShadow="0px 0px 19px 0px rgba(50, 50, 50, 0.1)"
              >
                <MessageCard
                  image={Check}
                  header="Manual Entry"
                  middleText="No Tracker? No problem! Record your activity within the app."
                  bg="white"
                  color="black"
                />
                <Box
                  cursor="pointer"
                  my={4}
                  bg="#2DCCD3"
                  fontSize="16px"
                  fontWeight="bold"
                  w="250px"
                  borderRadius="60px"
                  p={2}
                  alignSelf="center"
                >
                  <Text textAlign="center">Make a Manual Entry</Text>
                </Box>
              </Flex>
            )}
            <Flex
              direction="column"
              mt={6}
              mb={4}
              borderRadius="10px"
              boxShadow="0px 0px 19px 0px rgba(50, 50, 50, 0.1)"
              px={4}
              py={2}
            >
              <Text fontSize="16px" fontWeight="bold" textAlign="center">
                Have more questions?
              </Text>
              <Box
                cursor="pointer"
                my={4}
                bg="#2DCCD3"
                fontSize="16px"
                fontWeight="bold"
                w="250px"
                borderRadius="60px"
                p={2}
                alignSelf="center"
              >
                <Text textAlign="center">See FAQ</Text>
              </Box>
            </Flex>
          </>
        ) : (
          <Text>Loading...</Text>
        )}
      </Flex>
    </Flex>
  );
};

const MobilePreview = ({
  name,
  challenge_id,
  isRules,
}: {
  name: string;
  challenge_id?: number;
  isRules?: boolean;
}): JSX.Element => {
  const [{ value }] = useField(name);

  function transform(node: any, index: number) {
    if (node.type === 'tag' && node.name === 'ul') {
      return (
        <ul
          key={`${index}-1`}
          style={{
            listStylePosition: 'outside',
            marginLeft: '1em',
          }}
        >
          {processNodes(node.children, transform)}
        </ul>
      );
    }
    if (node.type === 'tag' && node.name === 'ol') {
      return (
        <ol
          style={{
            listStyleType: 'decimal',
            listStylePosition: 'outside',
            marginLeft: '1em',
          }}
          key={`${index}-1`}
        >
          {processNodes(node.children, transform)}
        </ol>
      );
    }
    if (
      node.type === 'tag' &&
      node.name === 'p' &&
      node.children.length === 0
    ) {
      node.name = 'br';
      return convertNodeToElement(node, index, transform);
    }
    if (node.type === 'tag' && node.name === 'p') {
      node.attribs.style = `margin-bottom:15px;${node.attribs.style}`;
      return convertNodeToElement(node, index, transform);
    }
  }

  if (isRules && challenge_id) {
    return <DynamicRules challenge_id={challenge_id} />;
  }

  return (
    <Flex direction="column" ml={[0, 4]} flex={1} mt={2}>
      <Text fontSize="12px" fontWeight="bold" color="#4f4f4f" mb={1}>
        PREVIEW
      </Text>
      <Flex
        p={2}
        borderRadius="5px"
        borderWidth="1px"
        minW="380px"
        maxW={['350px', '380px']}
        h="590px"
        direction="column"
        overflowX="hidden"
        letterSpacing={0.1}
        whiteSpace="pre-wrap"
      >
        {HtmlParser(value, {
          transform,
        })}
      </Flex>
    </Flex>
  );
};
export default MobilePreview;
