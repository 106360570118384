import api from 'api/instance';
import { MerchCatalogue, MerchItem } from 'types/merch';

const URL = `${process.env.REACT_APP_API_URL}/merch`;

export const getAllMerch = async (): Promise<MerchCatalogue> => {
  const { data } = await api.get<MerchCatalogue>(`${URL}/catalogue/read`);
  return data;
};

export const createMerch = async (
  values: Omit<MerchItem, 'id'>
): Promise<any> => {
  const { data } = await api.post(`${URL}/catalogue/create`, values);
  return data;
};

export const deleteMerch = async (id: number): Promise<any> => {
  const { data } = await api.get(`${URL}/catalogue/delete?id=${id}`);
  return data;
};

export const updateMerch = async (values: MerchItem): Promise<any> => {
  const { data } = await api.post(`${URL}/catalogue/update`, values);
  return data;
};
