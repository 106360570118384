import api from 'api/instance';
import { AxiosPromise } from 'axios';
import { ApiResponse } from 'types/api';
import { Session } from 'types/session';

const URL = `${process.env.REACT_APP_API_URL}/authentication`;

interface LoginPayload {
	email: string;
	password: string;
}

export const login = async (payload: LoginPayload): Promise<ApiResponse<Session>> => {
	const { data } = await api.post<ApiResponse<Session>>(`${URL}/login`, payload);
	
	console.log(process.env)
	console.log(URL)
	console.log(data)
	
	return data;
};

export const logout = (): AxiosPromise => api.post(`${URL}/logout`);