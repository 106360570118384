import React, { useEffect } from 'react';

import { login } from 'api/auth';
import { loginEvent } from '../../effector/session/events';
import { useMutation } from 'react-query';

import { LoginProps } from './Login.props';
import LoginView from './Login.view';

const LoginContainer = (props: LoginProps): JSX.Element => {
	const [submitLogin, { isLoading, data = {}, isSuccess, error }] = useMutation(login);
	useEffect(() => {
		if (isSuccess && !data.error) {
			loginEvent(data);
		}
	}, [isSuccess, data]);
	return (
		<LoginView
			submitLogin={submitLogin}
			isLoading={isLoading}
			error={data.error ? data.Message : ''}
		/>
	);
};

export default LoginContainer;
