import { Box, Spinner } from '@chakra-ui/core';
import React from 'react';

const LoadingIndicator = () => (
  <Box
    display="flex"
    height="70vh"
    width="100%"
    alignItems="center"
    justifyContent="center"
    bg="purple.50"
  >
    <Spinner size="xl" />
  </Box>
);

export default LoadingIndicator;
