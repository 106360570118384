import React from 'react';

import { Box, Flex, Stack, Text } from '@chakra-ui/core';

import { DashboardGeneratedProps } from './Dashboard.props';

const DashboardView = (props: DashboardGeneratedProps): JSX.Element => (
  <Flex flexDir="column" flex={1} p={3} alignItems="center">
    <Flex flex={1} maxW="1300px" w="100%" flexDirection="column">
      <Flex
        flex={1}
        m={3}
        direction={['column', 'column', 'row']}
        maxH={['100%', '100%', '500px']}
      >
        <Stack
          flex={1}
          m={3}
          boxShadow="0px 1px 7px 0px rgba(50, 50, 50, 0.26)"
          p={3}
          borderRadius="15px"
        >
          <Text
            fontSize="20px"
            fontFamily="Helvetica Neue"
            position="absolute"
            alignSelf="center"
          >
            <a href="/events">Events</a>
          </Text>
          <Flex
            w="100%"
            justifyContent="space-around"
            flex={1}
            alignItems="center"
            fontFamily="Helvetica Neue"
          >
            <Box color="blue.400">
              <Text textAlign="center" fontSize="60px" fontWeight="500">
                {props.eventStatus.upcoming}
              </Text>
              <Text fontSize={['20px', '30px']}>Upcoming</Text>
            </Box>
            <Box color="green.400">
              <Text textAlign="center" fontSize="60px" fontWeight="500">
                {props.eventStatus.active}
              </Text>
              <Text fontSize={['20px', '30px']}>Active</Text>
            </Box>
            <Box color="red.400">
              <Text textAlign="center" fontSize="60px" fontWeight="500">
                {props.eventStatus.expired}
              </Text>
              <Text fontSize={['20px', '30px']}>Expired</Text>
            </Box>
          </Flex>
        </Stack>
        <Stack flex={0.5} m={3}>
          <Flex
            flex={1}
            boxShadow="0px 1px 7px 0px rgba(50, 50, 50, 0.26)"
            p={3}
            borderRadius="15px"
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <Text fontSize="30px" fontWeight="bold">
              {props.charities.length}
            </Text>
            <Text>
              <a href="/charities">All Charities</a>
            </Text>
          </Flex>
          <Flex
            flex={1}
            boxShadow="0px 1px 7px 0px rgba(50, 50, 50, 0.26)"
            p={3}
            borderRadius="15px"
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <Text fontSize="30px" fontWeight="bold">
              {props.gyms.length}
            </Text>
            <Text>
              <a href="/gym">All Gyms</a>
            </Text>
          </Flex>
        </Stack>
      </Flex>
    </Flex>
  </Flex>
);

export default DashboardView;
