import React, { useEffect, useRef, useState } from 'react';

import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useDisclosure,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from '@chakra-ui/core';
import { AnimatePresence } from 'framer-motion';
import moment from 'moment';
import { ListLoadingIndicator } from 'routes/Events/Events.view';

import NotificationForm from '../NotificationForm';
import { NotificationsTableGeneratedProps } from './NotificationsTable.props';

const NotificationsTableView = (
  props: NotificationsTableGeneratedProps
): JSX.Element => {
  const [selectedItem, setSelectedItem] = useState<null | number>(null);
  const [showModal, setShowModal] = useState(false);
  const alertRef = useRef<HTMLElement>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [showModal]);

  useEffect(() => {
    if (props.isDeleteSuccess) {
      onClose();
    }
  }, [props.isDeleteSuccess, onClose]);

  return (
    <Flex
      flex={1}
      direction="column"
      mx={[2, 0]}
      maxW={[window.innerWidth * 0.95, 'auto']}
    >
      {!props.isEvent && <Text fontSize="3xl">All Notifications</Text>}
      <Button
        alignSelf="flex-end"
        w="150px"
        bg="teal.200"
        color="purple.800"
        fontWeight="bold"
        mb={5}
        onClick={() => {
          setSelectedItem(null);
          setShowModal(true);
        }}
      >
        NEW
      </Button>
      <Flex flex={1} mb={10} overflowX="auto">
        <Box
          fontSize="12px"
          borderRadius="2px"
          shadow="sm"
          w="100%"
          minW={props.isEvent ? '1000px' : '1200px'}
        >
          <Box bg="#e3e3e3" pr={props.notifications.length > 12 ? 4 : 0}>
            <Flex p={2} flex={1} fontWeight="600" color="grey">
              <Text flex={0.2}>ID</Text>
              <Text flex={1} mr={2}>
                Event Name
              </Text>
              <Text flex={1} mr={2}>
                Title
              </Text>
              <Text flex={1} mr={2}>
                Subject
              </Text>
              <Text flex={0.6}>Date/Time</Text>
              <Text flex={0.5}>Status</Text>
              {!props.isEvent && <Text flex={0.6}>Created By</Text>}
              <Flex flex={0.3} />
            </Flex>
          </Box>
          <Box
            maxH={window.innerHeight * 0.6}
            overflowY="auto"
            minW={props.isEvent ? '1000px' : '1200px'}
          >
            {props.isLoadingList ? (
              <ListLoadingIndicator />
            ) : (
              props.notifications.map((notification, i) => {
                return (
                  <Flex
                    flex={1}
                    px={2}
                    py={3}
                    bg={i % 2 === 0 ? 'white' : '#f6f6f6'}
                    key={`${notification.id}`}
                  >
                    <Text flex={0.2}>{notification.id}</Text>
                    <Text flex={1} isTruncated mr={2}>
                      {notification.challenge_name}
                    </Text>
                    <Text flex={1} isTruncated mr={2}>
                      {notification.title}
                    </Text>
                    <Text flex={1} isTruncated mr={2}>
                      {notification?.subject || ''}
                    </Text>
                    <Text flex={0.6}>
                      {notification.send_time === null
                        ? 'Saved as Draft'
                        : moment(notification.send_time)
                            .utc()
                            .tz('America/New_York')
                            .format('YYYY-MM-DD HH:mm:ss')}
                    </Text>
                    <Text flex={0.5}>
                      {notification.sent ? 'Sent' : 'Pending'}
                    </Text>
                    {!props.isEvent && (
                      <Text flex={0.6}>{notification.author_name}</Text>
                    )}
                    <Flex flex={0.3}>
                      <Icon
                        name="edit"
                        size="16px"
                        color="grey"
                        onClick={() => {
                          if (notification.sent) {
                            toast({
                              title: 'Cannot Update.',
                              description:
                                'Cannot edit an already sent Notification',
                              status: 'error',
                              duration: 9000,
                              isClosable: true,
                            });
                          } else {
                            setSelectedItem(notification.id);
                            setShowModal(true);
                          }
                        }}
                      />
                      <Icon name="copy" size="16px" mx={2} color="grey" />
                      <Icon
                        name="delete"
                        size="16px"
                        color="grey"
                        onClick={() => {
                          setSelectedItem(notification.id);
                          onOpen();
                        }}
                      />
                    </Flex>
                  </Flex>
                );
              })
            )}
          </Box>
        </Box>
      </Flex>
      <AnimatePresence>
        {showModal && (
          <NotificationForm
            isModal={showModal}
            closeModal={() => setShowModal(false)}
            notificationId={selectedItem}
            isEvent={props.isEvent || false}
            refetch={props.refetch}
            challenge_id={props.challenge_id}
            onSuccess={() => setShowModal(false)}
          />
        )}
      </AnimatePresence>
      <AlertDialog
        isOpen={isOpen}
        onClose={onClose}
        leastDestructiveRef={alertRef}
        isCentered
        blockScrollOnMount={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Notification
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure? You want to Delete this Notification?
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={alertRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                bg="Red"
                color="White"
                onClick={() => props.onDelete(selectedItem || 0)}
                ml={3}
                isLoading={props.isDeleting}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
};

export default NotificationsTableView;
