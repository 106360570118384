import React from 'react';

import { useToast } from '@chakra-ui/core';
import { getCharityDetails, updateCharity } from 'api/charities';
import { useMutation, useQuery } from 'react-query';
import { CharityDetails } from 'types/charities';

import { CharityUpdateProps } from './CharityUpdate.props';
import CharityUpdateView from './CharityUpdate.view';

const CharityUpdateContainer = (props: CharityUpdateProps): JSX.Element => {
  const charityId = parseInt(props.match.params.id);
  const toast = useToast();
  const { data, isLoading } = useQuery(
    `get-charity-${charityId}`,
    () => getCharityDetails(charityId),
    {
      onError: (err: any) => {
        toast({
          title: 'Error',
          description: err?.message || 'something went wrong',
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      },
    }
  );

  const [updateSubmit] = useMutation(updateCharity, {
    onSuccess: () => {
      toast({
        title: 'Success',
        description: 'Charity updated successfully',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });

      setTimeout(() => {
        props.history.push('/charities');
      }, 500);
    },
    onError: (err: any) => {
      toast({
        title: 'Error',
        description: err?.message || err?.Message || 'something went wrong',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    },
  });

  const onUpdate = async (details: CharityDetails) => {
    await updateSubmit(details);
  };

  return (
    <CharityUpdateView data={data} isLoading={isLoading} onUpdate={onUpdate} />
  );
};

export default CharityUpdateContainer;
