import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  Text,
} from '@chakra-ui/core';
import LoadingIndicator from 'components/common/LoadingIndicator';
import ConfirmationModalView from 'components/primitives/ConfirmationModal';
import FormikNumberInput from 'components/primitives/FormikNumberInput';
import FormikSelectInput from 'components/primitives/FormikSelectInput';
import { Form, Formik, useField } from 'formik';
import * as R from 'ramda';
import { AcitivtyScoring, EventActivityScoringRule } from 'types/events';

import { ScoringRulesGeneratedProps } from './ScoringRules.props';

const getScoringRules = (data: AcitivtyScoring[]) => {
  const rules: EventActivityScoringRule[] = [];
  data.forEach((item) =>
    item.scoring_rules.forEach((rule) => {
      const obj = { ...rule, activity_type: item.activity_type_id };
      rules.push(obj);
    })
  );
  return rules;
};

const getActivities = (data?: EventActivityScoringRule[]) => {
  const activities: number[] = [];
  data?.forEach((item) => activities.push(item.activity_type));
  return activities;
};

const DeleteIcon = ({
  onDeleteClick,
  isLoading,
}: {
  onDeleteClick: () => Promise<any>;
  isLoading: boolean;
}) => {
  return (
    <ConfirmationModalView
      message="Are you sure you want to permanently delete this scoring rule?"
      onConfirmClick={onDeleteClick}
      isLoading={isLoading}
    >
      <Icon name="delete" size="16px" color="grey" />
    </ConfirmationModalView>
  );
};

const MetricUnitInput = ({ metricType }: { metricType: string }) => {
  const [{ value }, , field] = useField('metric_unit');
  useEffect(() => {
    if (metricType === 'active_duration') {
      field.setValue('min');
    }
    if (metricType === 'steps') {
      field.setValue('step');
    }
  }, [metricType]);

  return (
    <FormControl w={['300px', '250px']}>
      {metricType === 'distance' ? (
        <FormikSelectInput
          name="metric_unit"
          label="METRIC UNIT"
          placeholder="Select a unit"
        >
          <option value="m">m</option>
          <option value="mi">mi</option>
        </FormikSelectInput>
      ) : (
        <>
          <FormLabel
            htmlFor="metric_unit"
            fontSize="12px"
            fontWeight="600"
            color="grey"
            mt="11px"
          >
            METRIC UNIT
          </FormLabel>
          <Input isReadOnly value={value} />
        </>
      )}
    </FormControl>
  );
};
const metricTypes = [
  {
    key: 'distance',
    value: 'distance',
  },
  {
    key: 'active duration',
    value: 'active_duration',
  },
  {
    key: 'steps',
    value: 'steps',
  },
];

const ScoringRulesView = ({
  isFetching,
  onScoringCreateSubmit,
  ...props
}: ScoringRulesGeneratedProps): JSX.Element => {
  const [editRule, setEditRule] = useState<EventActivityScoringRule | null>(
    null
  );
  const [isEditing, setIsEditing] = useState(false);

  const scoringRules =
    props.scoringRules?.activity_scoring &&
    getScoringRules(props.scoringRules.activity_scoring);

  const activities = getActivities(scoringRules);
  const activityTypes = props.activityTypes.filter((item) =>
    !isEditing
      ? !activities.includes(item.id)
      : item.id === editRule?.activity_type
  );

  const metrics = metricTypes.filter((item) =>
    !isEditing ? true : item.value === editRule?.metric_type
  );

  return (
    <Flex w="100%" maxW="1300px" direction="column">
      <Text fontSize="22px" fontWeight="600">
        {isEditing ? 'Update' : 'Create new'} Scoring Rule
      </Text>
      {!isFetching ? (
        <Formik<EventActivityScoringRule>
          initialValues={
            editRule
              ? editRule
              : {
                  activity_type: 0,
                  metric_type: '',
                  metric_unit: '',
                  metric_value: 0,
                  point_value: 0,
                }
          }
          enableReinitialize
          onSubmit={async (values, formik) => {
            const params = {
              challenge_id: props.challengeId,
              ...values,
            };
            formik.setSubmitting(true);
            if (!isEditing) {
              await onScoringCreateSubmit(params);
            } else {
              await props.onUpdateSubmit(params);
              setEditRule(null);
              setIsEditing(false);
            }
            formik.setSubmitting(false);
            formik.resetForm();
            props.refetch();
          }}
        >
          {({ isSubmitting, values, initialValues }) => (
            <Form>
              <Flex flex={1} direction="column" maxW="550px">
                <Flex direction={['column', 'row']}>
                  <FormikSelectInput
                    name="activity_type"
                    placeholder="Select an Activity"
                    label="ACTIVITY"
                    w={['300px', '250px']}
                  >
                    {activityTypes.map((item) => (
                      <option value={item.id} key={`${item.id}`}>
                        {item.label}
                      </option>
                    ))}
                  </FormikSelectInput>
                </Flex>
                <Flex direction={['column', 'row']} mt={8}>
                  <FormikSelectInput
                    name="metric_type"
                    placeholder="Select a Metric Type"
                    label="METRIC TYPE"
                    w={['300px', '250px']}
                  >
                    {metrics.map((item, index) => (
                      <option key={`${item.key}-${index}`} value={item.value}>
                        {item.key}
                      </option>
                    ))}
                  </FormikSelectInput>
                  <Box h="20px" w="30px" />
                  <MetricUnitInput metricType={values.metric_type} />
                </Flex>
                <Flex direction={['column', 'row']} mt={8}>
                  <FormikNumberInput
                    name="metric_value"
                    label="METRIC VALUE"
                    w={['300px', '250px']}
                  />
                  <Box h="20px" w="10px" />
                  <FormikNumberInput
                    name="point_value"
                    label="POINT VALUE"
                    w={['300px', '250px']}
                  />
                </Flex>
                <Flex direction={['column', 'row']} mt={8}>
                  <Button
                    bg={isEditing ? 'yellow.500' : 'purple.800'}
                    color="white"
                    w="200px"
                    type="submit"
                    isLoading={isSubmitting}
                    isDisabled={R.equals(initialValues, values)}
                  >
                    {isEditing ? 'UPDATE' : 'SUBMIT'}
                  </Button>
                  {isEditing && (
                    <Button
                      mt={[4, 0]}
                      ml={[0, 4]}
                      bg="red.300"
                      color="white"
                      w="200px"
                      onClick={() => {
                        setEditRule(null);
                        setIsEditing(false);
                      }}
                    >
                      CLEAR
                    </Button>
                  )}
                </Flex>
              </Flex>
            </Form>
          )}
        </Formik>
      ) : (
        <LoadingIndicator />
      )}
      <Flex flex={1} direction="column" my={10}>
        <Text my={4} fontSize="20px" fontWeight="600">
          All Scoring Rules
        </Text>
        <Flex
          maxW="850px"
          fontSize="12px"
          fontWeight="600"
          color="grey"
          textTransform="uppercase"
          mb={1}
        >
          <Text flex={0.1} />
          <Text flex={0.2}>Activity</Text>
          <Text flex={0.2}>Metric Type</Text>
          <Text flex={0.2} textAlign="center">
            Metric Unit
          </Text>
          <Text flex={0.2}>Metric Value</Text>
          <Text flex={0.2}>Point Value</Text>
          <Box w="10px" bg="yellow" />
          <Box w="10px" />
        </Flex>
        <Flex direction="column" p={2} borderWidth="2px" maxW="850px">
          {scoringRules ? (
            scoringRules.map((item, index) => {
              const activity = props.scoringRules?.activity_scoring.find(
                (act) => act.activity_type_id === item.activity_type
              );
              return (
                <Flex
                  key={`${item.activity_type}-${index}`}
                  flex={1}
                  alignItems="center"
                  my={2}
                >
                  <Text flex={0.1}>{index + 1})</Text>
                  <Text flex={0.2}>{activity?.activity_type_label}</Text>
                  <Text flex={0.2}>{item.metric_type}</Text>
                  <Text flex={0.2} textAlign="center">
                    {item.metric_unit}
                  </Text>
                  <Text flex={0.2} textAlign="center">
                    {item.metric_value}
                  </Text>
                  <Text flex={0.2} textAlign="center">
                    {item.point_value}
                  </Text>
                  <Icon
                    name="edit"
                    mr={4}
                    color="grey"
                    mt={1}
                    onClick={() => {
                      setEditRule(scoringRules[index]);
                      setIsEditing(true);
                      window.scroll({ behavior: 'smooth', top: 0 });
                    }}
                  />
                  <DeleteIcon
                    onDeleteClick={async () => {
                      const params = {
                        challenge_id: props.challengeId,
                        ...scoringRules[index],
                      };
                      await props.onDeleteSubmit(params);
                      props.refetch();
                      setIsEditing(false);
                      setEditRule(null);
                    }}
                    isLoading={props.isDeleting}
                  />
                </Flex>
              );
            })
          ) : (
            <Text>Loading...</Text>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ScoringRulesView;
