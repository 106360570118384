import React, { useEffect, useState } from 'react';

import { Box, Spinner } from '@chakra-ui/core';
import { AuthGuardPublicProps } from 'components/core/AuthGuard/AuthGuard.props';
import { useStore } from 'effector-react';
import { SessionStore } from '../../../effector/session/store';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTES } from 'routes';

const AuthGuard = ({
  children,
  notFound,
  auth,
}: AuthGuardPublicProps): JSX.Element => {
  const history = useHistory();
  const { pathname } = useLocation();
  const [redirect, setRedirecting] = useState(false);

  const sessionRedirectRoutes = ['/', ROUTES.LOGIN.path];
  const session = useStore(SessionStore);
  const key = session?.key || '';
  useEffect(() => {
    const checkRedirect = (key?: string) => {
      if (key) {
        if (sessionRedirectRoutes.includes(pathname))
          history.replace(ROUTES.EVENTS.path);
      } else if (notFound) {
        setRedirecting(true);

        setTimeout(() => {
          setRedirecting(false);
          if (key) {
            history.replace(ROUTES.DASHBOARD.path);
          } else if (!key) history.replace(ROUTES.LOGIN.path);
        }, 1500);
      } else {
        history.replace(ROUTES.LOGIN.path);
      }
    };
    checkRedirect(key);
    // eslint-disable-next-line
  }, [key, auth, notFound, history]);

  return (
    <>
      {redirect ? (
        <Box
          display="flex"
          height="100vh"
          width="100%"
          alignItems="center"
          justifyContent="center"
          bg="purple.50"
        >
          <Spinner size="xl" />
        </Box>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default React.memo(AuthGuard);
