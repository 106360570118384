import React, {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useCallback,
  useImperativeHandle,
} from 'react';

import {
  Box,
  Tab,
  Tabs,
  Flex,
  Text,
  Stack,
  Button,
  TabList,
  useToast,
  TabPanel,
  FormLabel,
  TabPanels,
  AlertDialog,
  useDisclosure,
  AspectRatioBox,
  AlertDialogBody,
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialogOverlay,
  AlertDialogContent,
} from '@chakra-ui/core';

import * as R from 'ramda';

import { Form, Formik, useFormikContext } from 'formik';

import { useLocation } from 'react-router-dom';

import { uploadEventImage } from 'api/events';

import { MobilePreview } from 'utils/MobilePreview';

import FormikImageUpload from '../FormikImageUpload';
import FormikInput from 'components/primitives/FormikInput';
import FormikEditor from 'components/primitives/FormikEditor';
import FormikSwitch from 'components/primitives/FormikSwitch';
import FormikNumberInput from 'components/primitives/FormikNumberInput';
import AdmissionTierForm from 'components/primitives/AdmissionTierForm';
import FormikDateTimePicker from 'components/primitives/FormikDateTimePicker';

import {
  validateDates,
  validationSchema,
  getFormattedValues,
} from './EventDetails.utils';

import {
  FormType,
  FormikRefProps,
  BottomTabProps,
  UpdateAlertType,
  UpdateAlertProps,
  EventDetailsProps,
  ToggleSwitchProps,
} from './EventDetails.props';

const direction: any = [
  'column-reverse',
  'column-reverse',
  'column-reverse',
  'row',
];

const containerDirection: any = ['column', 'column', 'row'];

// component to handle external events on form change
const FormikRef = ({ onFormChange }: FormikRefProps) => {
  const formikRef = useFormikContext<any>();
  const { initialValues, values } = formikRef;

  useEffect(() => {
    if (!R.equals(initialValues, values)) {
      onFormChange && onFormChange(true);
    } else {
      onFormChange && onFormChange(false);
    }
  }, [formikRef, initialValues, values, onFormChange]);

  return <div />;
};

const ToggleSwitch = ({
  label,
  name,
  helpText,
  toggleSwitch,
}: ToggleSwitchProps) => (
  <Flex w="100%" direction="column" mb={2}>
    <Flex
      mt={5}
      width="100%"
      align="center"
      maxWidth="300px"
      justify="space-between"
    >
      <FormLabel htmlFor={name} fontSize="12px" fontWeight="600">
        {label}
      </FormLabel>
      <FormikSwitch name={name} size="md" toggleSwitch={toggleSwitch} />
    </Flex>
    <FormLabel
      ml={1}
      maxW="280px"
      htmlFor={name}
      fontSize="12px"
      color="#9a9898"
      flexWrap="wrap"
    >
      {helpText}
    </FormLabel>
  </Flex>
);

const UpdateAlert: UpdateAlertType = forwardRef(function UpdateAlert(
  { onSubmit }: UpdateAlertProps,
  ref
) {
  const cancelRef = useRef(null);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { values, setSubmitting } = useFormikContext<FormType>();

  useImperativeHandle(ref, () => ({
    onOpen,
  }));

  const onClick = useCallback(() => {
    const formattedValues = getFormattedValues(values);
    setSubmitting(true);
    onSubmit(formattedValues);
    onClose();
    // eslint-disable-next-line
  }, [values]);

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
      blockScrollOnMount={false}
      leastDestructiveRef={cancelRef}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Update Event
          </AlertDialogHeader>
          <AlertDialogBody>
            Are you sure you want to Update the Event Details?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button bg="Red" color="white" onClick={onClick} ml={3}>
              Submit
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
});

const BottomTabs = ({ challenge_id }: BottomTabProps) => {
  const { pathname } = useLocation();

  return (
    <Tabs mt={5} ml={[0, 0, 5]} variant="enclosed">
      <TabList>
        <Tab fontSize="12px" fontWeight="600" color="grey">
          EVENT INFO
        </Tab>
        <Tab fontSize="12px" fontWeight="600" color="grey" isTruncated>
          PRIZE DESCRIPTION
        </Tab>
        {pathname !== '/event/create' && (
          <Tab fontSize="12px" fontWeight="600" color="grey">
            RULES
          </Tab>
        )}
      </TabList>
      <TabPanels>
        <TabPanel>
          <Flex direction={containerDirection} mt={2}>
            <FormikEditor name="description" />
            <MobilePreview name="description" />
          </Flex>
        </TabPanel>
        <TabPanel>
          <Flex direction={containerDirection} mt={2}>
            <FormikEditor name="prize_description" />
            <MobilePreview name="prize_description" />
          </Flex>
        </TabPanel>
        {pathname !== '/event/create' && (
          <TabPanel>
            <Flex direction={containerDirection} mt={2} flex={1}>
              <Text flex={1} m={4}>
                Rules will be dynamically created and previewed to the right
              </Text>
              <MobilePreview name="rules" isRules challenge_id={challenge_id} />
            </Flex>
          </TabPanel>
        )}
      </TabPanels>
    </Tabs>
  );
};

// Main Parent View Component
const EventDetailsView = ({
  totalTeams,
  ...props
}: EventDetailsProps): JSX.Element => {
  const toast = useToast();
  const { pathname } = useLocation();
  const [submitFlag, setSubmitFlag] = useState(true);
  const alertRef = useRef<{ onOpen: () => void }>(null);
  const [isDraft, setIsDraft] = useState(props.data?.draft);
  const [isAdmissionModal, setIsAdmissionModal] = useState(false);

  useEffect(() => {
    if (pathname === '/event/create') {
      setSubmitFlag(Boolean(isDraft));
    } else {
      if (totalTeams && totalTeams > 0) {
        setSubmitFlag(true);
      } else {
        setSubmitFlag(Boolean(isDraft));
      }
    }
    // eslint-disable-next-line
  }, [props.data, totalTeams, isDraft]);

  const onSubmit = useCallback(
    (values, formik) => {
      if (submitFlag) {
        if (pathname === '/event/create') {
          const formattedValues = getFormattedValues(values);
          props.onSubmit(formattedValues);
        } else {
          formik.setSubmitting(false);
          alertRef.current?.onOpen();
        }
      } else {
        formik.setSubmitting(false);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        toast({
          title: 'Error',
          duration: 9000,
          status: 'error',
          isClosable: true,
          description: `Cannot Save event with no Teams.
        Either Create a Team or save event as Draft`,
        });
      }
    },
    // eslint-disable-next-line
    [props.onSubmit]
  );

  return (
    <Formik<FormType>
      enableReinitialize
      onSubmit={onSubmit}
      validate={validateDates}
      innerRef={props.formikRef}
      initialValues={props.data || {}}
      validationSchema={validationSchema}
    >
      {({ errors, values, initialValues, isSubmitting, isValid }) => (
        <Form>
          <Flex flex={1} direction={direction} justify="space-between">
            <Stack
              flex={1}
              maxW="750px"
              display="flex"
              ml={[0, 0, 5]}
              direction="column"
            >
              <Text fontSize="12px" fontWeight="600" color="grey">
                EVENT HEADER IMAGE
              </Text>
              <Flex direction="column" flex={1} maxW="600px">
                <AspectRatioBox
                  maxW="600px"
                  ratio={2}
                  display="flex"
                  maxH={['200px', '300px']}
                >
                  <FormikImageUpload
                    name="logo"
                    width="100%"
                    height="100%"
                    s3Key="challenges"
                    uploadHandler={uploadEventImage}
                    imageProps={{ objectFit: 'cover' }}
                  />
                </AspectRatioBox>
                <Text fontSize="12px" color="#9a9898" textAlign="center">
                  image dimensions goes here
                </Text>
              </Flex>
              <FormikInput
                name="name"
                label="EVENT NAME*"
                error={errors.name}
              />
              <FormikDateTimePicker
                name="start"
                label="EVENT START DATE*"
                error={errors.start}
                helper="Enter time in Eastern Time (ET)"
                timeFormat="HH:mm:ss"
                isValidDate={() => true}
              />
              <FormikDateTimePicker
                name="end"
                label="EVENT END DATE*"
                error={errors.end}
                helper="Enter time in Eastern Time (ET)"
                timeFormat="HH:mm:ss"
                isValidDate={() => true}
              />
              <FormikDateTimePicker
                name="start_registration"
                label="EVENT START REGISTRATION DATE*"
                error={errors.start_registration}
                helper="Enter time in Eastern Time (ET)"
                timeFormat="HH:mm:ss"
                isValidDate={() => true}
              />
              <FormikDateTimePicker
                name="end_registration"
                label="EVENT END REGISTRATION DATE*"
                error={errors.end_registration}
                helper="Enter time in Eastern Time (ET)"
                timeFormat="HH:mm:ss"
                isValidDate={() => true}
              />
              <FormikDateTimePicker
                name="start_fundraising"
                label="EVENT START FUNDRAISING DATE*"
                error={errors.start_fundraising}
                helper="Enter time in Eastern Time (ET)"
                timeFormat="HH:mm:ss"
                isValidDate={() => true}
              />
              <FormikDateTimePicker
                name="end_fundraising"
                label="EVENT END FUNDRAISING DATE*"
                error={errors.end_fundraising}
                helper="Enter time in Eastern Time (ET)"
                timeFormat="HH:mm:ss"
                isValidDate={() => true}
              />
              <FormikInput
                name="website_url"
                label="WEBSITE URL"
                helperText="Example: https://www.kilterrewards.com/events/tri-4-schools-new-year-fitness-challenge-2021"
              />
              <FormikInput name="short_description" label="SHORT DESCRIPTION" />
              <FormikInput name="join_redirect_url" label="JOIN REDIRECT URL" />
              <Flex
                mt={4}
                flex={1}
                justifyContent="space-between"
                direction={containerDirection}
              >
                <FormikNumberInput
                  name="point_limit_per_day"
                  label="POINT LIMIT PER DAY"
                />
              </Flex>
              <Flex
                mt={4}
                flex={1}
                justifyContent="space-between"
                direction={containerDirection}
              >
                <FormikNumberInput
                  isPrice
                  name="prize_pool"
                  label="PRIZE POOL"
                  maxW={['100%', '95%']}
                  helperText="This section is used to display a dollar amount that’s available to unlock for additional donations or cash giveaways. For more help, reach out to friends@kilterrewards.com"
                />
                <FormikNumberInput
                  isPrice
                  name="fundraising_goal"
                  label="TOTAL FUNDRAISING GOAL"
                />
              </Flex>
              <Flex
                flex={1}
                justifyContent="space-between"
                direction={containerDirection}
              >
                <FormikInput
                  maxW={['100%', '95%']}
                  name="free_admission_code"
                  label="FREE ADMISSION CODE"
                  helperText="Enter a six digit code. This will allow registrants to sign up for free."
                />
                <FormikInput
                  name="p2p_message"
                  label="P2P MESSAGE"
                  helperText="This is the message that is auto-filled in when a participant requests donations from friends, family, or others via text, email, or other communications on their phone."
                />
              </Flex>
            </Stack>
            <Stack
              display="flex"
              direction="column"
              alignItems="center"
              p={5}
              pt={0}
              color="grey"
            >
              <Text fontSize="12px" fontWeight="600" w="100%">
                THUMBNAIL LOGO
              </Text>
              <Box>
                <FormikImageUpload
                  name="logo_thumbnail"
                  s3Key="challenges"
                  uploadHandler={uploadEventImage}
                  height={300}
                  width={300}
                />
                <Text fontSize="12px" color="#9a9898" textAlign="center">
                  image dimensions goes here
                </Text>
              </Box>
              <ToggleSwitch
                name="draft"
                label="DRAFT"
                helpText="Turn ON to save this Event as Draft"
                toggleSwitch={(val) => setIsDraft(val)}
              />
              <ToggleSwitch
                name="teams"
                label="TEAMS"
                helpText="Turn this ON if this event has teams."
              />
              <ToggleSwitch
                name="paid"
                label="PAID"
                toggleSwitch={(value: boolean) =>
                  pathname === '/event/create' && setIsAdmissionModal(value)
                }
                helpText="Turn ON to create paid registration tiers for participants. If OFF, this event is free to join."
              />
              <ToggleSwitch
                name="p2p"
                label="PEER-TO-PEER FUNDRAISING"
                helpText="If toggled ON, users will have access to use the Get Donations button."
              />
              <ToggleSwitch name="streak_bonus" label="STREAK BONUS" />
              <ToggleSwitch name="extra_mile_bonus" label="EXTRA MILE BONUS" />
              <ToggleSwitch name="tax_receipt" label="TAX RECEIPT" />
              <ToggleSwitch name="donate_button" label="DONATE BUTTON" />
              <ToggleSwitch
                name="use_custom_scoring"
                label="USE CUSTOM SCORING"
              />
              <ToggleSwitch
                name="allow_manual_activity"
                label="ALLOW MANUAL ACTIVITY"
              />
              <ToggleSwitch
                name="allow_partial_credit"
                label="ALLOW PARTIAL CREDIT"
              />
              <ToggleSwitch name="redirect_on_join" label="REDIRECT ON JOIN" />
            </Stack>
          </Flex>
          <BottomTabs challenge_id={props.data?.id} />
          <Box ml={[0, 0, 5]} mb={10}>
            {props.children ? (
              props.children
            ) : (
              <Button
                my={4}
                w="100px"
                color="white"
                type="submit"
                bg="purple.800"
                fontWeight="bold"
                isLoading={isSubmitting}
                isDisabled={R.equals(initialValues, values) || !isValid}
              >
                SAVE
              </Button>
            )}
          </Box>
          <UpdateAlert onSubmit={props.onSubmit} ref={alertRef} />
          {pathname === '/event/create' && (
            <AdmissionTierForm
              name="admission"
              isOpen={isAdmissionModal}
              closeModal={() => setIsAdmissionModal(false)}
            />
          )}
          {pathname !== '/event/create' && (
            <FormikRef onFormChange={props.onFormChange} />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default EventDetailsView;
