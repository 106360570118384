import React from 'react';

import { fetchAll } from 'api/events';
import { useQuery } from 'react-query';

import { NotificationsProps } from './Notifications.props';
import NotificationsView from './Notifications.view';

const NotificationsContainer = (_: NotificationsProps): JSX.Element => {
  const { data = [] } = useQuery(`events list`, fetchAll);

  return <NotificationsView events={data} />;
};

export default NotificationsContainer;
