import React, { useEffect, useState } from 'react';

import { Button, Flex, Icon, Text } from '@chakra-ui/core';
import { Formik, useField } from 'formik';
import { Admission } from 'types/events';

import FormikInput from '../FormikInput';
import FormikNumberInput from '../FormikNumberInput';
import FormikTextArea from '../FormikTextArea';
import MotionModal from '../MotionModal';
import { AdmissionTierFormProps } from './AdmissionTierForm.props';

const AdmissionTierFormView = (props: AdmissionTierFormProps): JSX.Element => {
  const [{ value }, , field] = useField(props.name);
  const [, , fieldPaid] = useField('paid');
  const [tiersList, setTiersList] =
    useState<Array<Omit<Admission, 'id' | 'challenge_id'>>>(value);

  useEffect(() => {
    if (value) setTiersList(value);
  }, [value]);

  useEffect(() => {
    if (props.isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [props.isOpen]);

  const newArr: any = [];
  return (
    <Formik
      initialValues={{
        label: '',
        price: 0,
        description: '',
      }}
      onSubmit={() => {
        console.log('');
      }}
    >
      {({ values, resetForm }) => (
        <MotionModal
          w={['98%', '400px', '500px']}
          h={['500px', '685px']}
          isModal={props.isOpen}
          closeModal={() => {
            props.closeModal();
            fieldPaid.setValue(false);
          }}
          title="Create Admission Price Tier"
        >
          <Flex flex={1} p={2} direction="column">
            <FormikInput name="label" label="LABEL" />
            <FormikNumberInput isPrice name="price" label="PRICE" />
            <FormikTextArea name="description" label="DESCRIPTION" size="sm" />
            <Button
              size="md"
              bg="blue.300"
              color="white"
              alignSelf="center"
              w="250px"
              mt={5}
              isDisabled={values.label === '' || values.price === 0}
              onClick={() => {
                newArr.push(values);
                resetForm();
                if (tiersList) {
                  setTiersList([...tiersList, ...newArr]);
                } else {
                  setTiersList(newArr);
                }
              }}
            >
              ADD
            </Button>
            <Flex
              bg="#f1f1f1"
              p={1}
              mt={2}
              direction="column"
              fontSize="12px"
              fontWeight="600"
              color="grey"
              flex={1}
            >
              {tiersList ? (
                tiersList.map((item, index) => (
                  <Flex
                    key={`${item.label}-1`}
                    flex={1}
                    alignItems="center"
                    my={1}
                    justifyContent="space-around"
                  >
                    <Text flex={0.1}>{index + 1})</Text>
                    <Text flex={1}>{item.label}</Text>
                    <Text flex={0.5}>$ {item.price}</Text>
                    <Icon
                      flex={0.5}
                      name="delete"
                      ml={2}
                      onClick={() => {
                        setTiersList((prevState) =>
                          prevState.filter((_, i) => i !== index)
                        );
                      }}
                    />
                  </Flex>
                ))
              ) : (
                <Text textAlign="center">NO TIERS</Text>
              )}
            </Flex>
            <Button
              size="md"
              bg="green.300"
              color="white"
              alignSelf="center"
              w="250px"
              mt={2}
              onClick={() => {
                fieldPaid.setValue(true);
                field.setValue(tiersList);
                props.closeModal();
              }}
            >
              DONE
            </Button>
          </Flex>
        </MotionModal>
      )}
    </Formik>
  );
};

export default AdmissionTierFormView;
