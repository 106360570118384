import React from 'react';

import { useToast } from '@chakra-ui/core';
import { deleteNotification, getAllNotifications } from 'api/notification';
import { useMutation, useQuery } from 'react-query';

import { NotificationsTableProps } from './NotificationsTable.props';
import NotificationsTableView from './NotificationsTable.view';

const NotificationsTableContainer = (
  props: NotificationsTableProps
): JSX.Element => {
  const toast = useToast();
  const challenge_id = props.challenge_id ? props.challenge_id : null;

  // fetch all notifications
  const {
    data: notifications,
    isLoading: isLoadingList,
    refetch,
  } = useQuery('fetch notifications', () =>
    getAllNotifications({
      challenge_id,
      team_id: null,
    })
  );

  const [
    deleteNotificationSubmit,
    { isLoading: isDeleting, isSuccess: isDeleteSuccess },
  ] = useMutation(deleteNotification, {
    onSuccess: () => {
      toast({
        title: 'Success',
        description: 'Notification Deleted successfully',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    },
  });

  // delete-notification
  const onDelete = async (id: number) => {
    await deleteNotificationSubmit(id);
    refetch();
  };

  return (
    <NotificationsTableView
      isEvent={props.isEvent || false}
      notifications={notifications || []}
      isLoadingList={isLoadingList}
      isDeleting={isDeleting}
      onDelete={onDelete}
      refetch={() => refetch()}
      challenge_id={props.challenge_id}
      isDeleteSuccess={isDeleteSuccess}
    />
  );
};

export default NotificationsTableContainer;
