import React, { useState } from 'react';

import { Button, Flex, FormLabel, Stack, Text } from '@chakra-ui/core';
import { fetchAllCharities } from 'api/charities';
import { uploadTeamImage } from 'api/teams';
import FormikInput from 'components/primitives/FormikInput';
import FormikNumberInput from 'components/primitives/FormikNumberInput';
import FormikSelectInput from 'components/primitives/FormikSelectInput';
import FormikSwitch from 'components/primitives/FormikSwitch';
import MotionModal from 'components/primitives/MotionModal';
import { Form, Formik, isNaN } from 'formik';
import * as R from 'ramda';
import { useQuery } from 'react-query';
import { Team } from 'types/team';

import FormikImageUpload from '../FormikImageUpload';
import { TeamFormProps } from './TeamForm.props';

const TeamForm = (props: TeamFormProps): JSX.Element => {
  const [deleteLoading, setDeleteLoading] = useState(false);
  const onDelete = async () => {
    if (props.data && props.onDelete) {
      setDeleteLoading(true);
      await props.onDelete(props.data?.id);
      setDeleteLoading(false);
    }
  };
  const { data } = useQuery('fetch all charities', fetchAllCharities);

  return (
    <Formik<Partial<Team>>
      initialValues={props.data || {}}
      onSubmit={async (values, formik) => {
        const formattedValues = {
          ...values,
          charity_id: isNaN(values.charity_id) ? undefined : values.charity_id,
        };
        formik.setSubmitting(true);
        await props.onSubmit(formattedValues);
        formik.setSubmitting(false);
        props.closeModal();
      }}
      validate={(values) => {
        const selectedCharity = values.charity_id;
        const errors: any = {};
        if (
          isNaN(selectedCharity) ||
          selectedCharity === null ||
          selectedCharity === undefined
        ) {
          errors.charity_id =
            'Charity is required. Please select charity or get charity added to DB.';
        }
        return errors;
      }}
      validateOnChange
    >
      {({ isSubmitting, values, initialValues, isValid }) => (
        <Form style={{ width: '100%' }}>
          <MotionModal
            title={`${props.data ? 'Update' : 'Create'} Team`}
            isModal={props.isModal}
            closeModal={props.closeModal}
            w={['98%', '400px', '500px']}
            h={['500px', '685px']}
          >
            <Stack spacing={2} w="100%" mx={3}>
              <Flex direction="column">
                <FormikImageUpload
                  width={100}
                  height={100}
                  name="logo"
                  s3Key="partners"
                  uploadHandler={uploadTeamImage}
                  flexProps={{ alignSelf: 'center' }}
                />
                <Text fontSize="12px" color="#9a9898" textAlign="center">
                  image dimensions goes here
                </Text>
              </Flex>
              <FormikInput name="name" label="TEAM NAME*" />
              <FormikInput name="team_url" label="TEAM URL" />
              <FormikSelectInput
                name="charity_id"
                placeholder="Select a Charity"
                label="CHARITY"
              >
                {data?.map((charity) => (
                  <option value={charity.id} key={`${charity.id}`}>
                    {charity.name}
                  </option>
                ))}
              </FormikSelectInput>
              <Stack isInline spacing={6}>
                <Stack mt="18px">
                  <FormLabel
                    htmlFor="teams"
                    color="grey"
                    fontSize="12px"
                    fontWeight="600"
                  >
                    PRIVATE
                  </FormLabel>
                  <FormikSwitch name="private" w="40px" />
                </Stack>
                <FormikInput
                  label="JOIN CODE"
                  name="join_code"
                  placeholder="eg - A2S43C"
                  isDisabled={
                    props.data
                      ? !props.data?.private && !values.private
                      : !values.private
                  }
                  bg={values.private ? '#fff' : '#f1f1f1'}
                  helperText="Toggle ON Private to create a private six-digit join code"
                />
              </Stack>
              <FormikNumberInput
                label="TEAM SIZE LIMIT"
                name="slots_remaining"
                placeholder="Slots Remaining"
              />
              <Stack isInline w="100%" my={3}>
                <Button
                  type="button"
                  onClick={onDelete}
                  size="md"
                  bg="red.500"
                  color="white"
                  isLoading={deleteLoading}
                  isDisabled={props.data ? false : true}
                  flex={1}
                >
                  Delete
                </Button>
                {props.data ? (
                  <Button
                    type="submit"
                    size="md"
                    bg="orange.500"
                    color="white"
                    isLoading={isSubmitting}
                    flex={1}
                    isDisabled={R.equals(initialValues, values) || !isValid}
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    size="md"
                    bg="purple.500"
                    color="white"
                    isLoading={isSubmitting}
                    isDisabled={(values.name ? false : true) || !isValid}
                    flex={1}
                  >
                    Create
                  </Button>
                )}
              </Stack>
            </Stack>
          </MotionModal>
        </Form>
      )}
    </Formik>
  );
};

export default TeamForm;
