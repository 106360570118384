import React from 'react';

import {
  FormControl,
  FormHelperText,
  FormLabel,
  Select,
  Text,
} from '@chakra-ui/core';
import { useField } from 'formik';

import { FormikSelectInputProps } from './FormikSelectInput.props';

const FormikSelectInputView = ({
  name,
  label,
  helperText,
  error,
  placeholder,
  children,
  setEvent,
  ...props
}: FormikSelectInputProps): JSX.Element => {
  const [{ value }, meta, field] = useField(name);

  return (
    <FormControl mt={2}>
      {label && (
        <FormLabel htmlFor={name} fontSize="12px" fontWeight="600" color="grey">
          {label}
        </FormLabel>
      )}
      <Select
        id={name}
        variant="outline"
        placeholder={placeholder}
        onChange={(e) => {
          if (
            name === 'team_id' ||
            name === 'challenge_id' ||
            name === 'charity_id' ||
            name === 'activity_type'
          ) {
            if (isNaN(parseInt(e.target.value))) {
              field.setValue(null);
            } else {
              field.setValue(parseInt(e.target.value));
              if (name === 'challenge_id' && setEvent) {
                setEvent(parseInt(e.target.value));
              }
            }
          } else {
            field.setValue(e.target.value);
          }
        }}
        value={value}
        minW="200px"
        {...props}
      >
        {children}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {(error || meta.error) && (
        <Text color="red.500" fontSize="sm">
          {error || meta.error}
        </Text>
      )}
    </FormControl>
  );
};

export default FormikSelectInputView;
