import React from 'react';

import { useToast } from '@chakra-ui/core';
import { createCharity } from 'api/charities';
import { useMutation } from 'react-query';
import { CharityDetails } from 'types/charities';

import { CharityCreateProps } from './CharityCreate.props';
import CharityCreateView from './CharityCreate.view';

const CharityCreateContainer = (props: CharityCreateProps): JSX.Element => {
  const toast = useToast();

  const [createCharitySubmit] = useMutation(createCharity, {
    onSuccess: () => {
      toast({
        title: 'Success',
        description: 'Charity created successfully',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      setTimeout(() => {
        props.history.push('/charities');
      }, 500);
    },
    onError: (err: any) => {
      toast({
        title: 'Error',
        description: err?.Message || 'something went wrong',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    },
  });

  const onSubmit = async (details: CharityDetails) => {
    await createCharitySubmit(details);
  };

  return <CharityCreateView onSubmit={onSubmit} />;
};

export default CharityCreateContainer;
