import api from 'api/instance';
import { Notification } from 'types/notification';

const URL = `${process.env.REACT_APP_API_URL}/push_notifications`;

export const createNotification = async (
  details: Partial<Notification>
): Promise<any> => {
  const { data } = await api.post(`${URL}/scheduled/create`, details);
  return data;
};

export const sendTestNotification = async (
  details: Partial<Notification>
): Promise<any> => {
  const { data } = await api.post(`${URL}/test`, details);
  return data;
};

export const deleteNotification = async (id: number): Promise<any> => {
  const { data } = await api.get(`${URL}/scheduled/delete?id=${id}`);
  return data;
};

export const updateNotification = async (
  details: Partial<Notification>
): Promise<any> => {
  const { data } = await api.post(`${URL}/scheduled/update`, details);
  return data;
};

export const getAllNotifications = async ({
  challenge_id,
  team_id,
}: {
  challenge_id?: number | null;
  team_id?: number | null;
}): Promise<Notification[]> => {
  const { data } = await api.get<Notification[]>(
    `${URL}/scheduled/list?challenge_id=${challenge_id}&team_id=${team_id}`
  );
  return data;
};

export const getNotificationDetails = async (
  id: number
): Promise<Notification> => {
  const { data } = await api.get<Notification>(
    `${URL}/scheduled/read?id=${id}`
  );
  return data;
};
