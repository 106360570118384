import * as yup from 'yup';

import moment from 'moment';

import { format } from 'date-fns';
import { formatToTimeZone } from 'date-fns-timezone';

export const validationSchema = yup.object().shape({
  name: yup.string().required('Event Name is required'),
  start: yup.string().required('Event Start Date is required'),
});

export const validateDates = (val: any): any => {
  const errors: {
    end?: string;
    end_fundraising?: string;
    end_registration?: string;
    start_registration?: string;
  } = {};

  if (val.end && val.start) {
    if (new Date(Date.parse(val.end)) < new Date(Date.parse(val.start))) {
      errors.end = 'Event End Date should be later than the Event Start Date';
    }
  }

  if (val.end && val.start_registration) {
    if (
      new Date(Date.parse(val.end)) <
      new Date(Date.parse(val.start_registration))
    ) {
      errors.start_registration =
        'Event Start Registration Date should be before the Event End Date';
    }
  }

  if (val.end_registration && val.start_registration) {
    if (
      new Date(Date.parse(val.end_registration)) <
      new Date(Date.parse(val.start_registration))
    ) {
      errors.end_registration =
        'Event End Registration Date should be later than the Event Start Registration Date';
    }
  }

  if (val.start_fundraising && val.end_fundraising) {
    if (
      new Date(Date.parse(val.end_fundraising)) <
      new Date(Date.parse(val.start_fundraising))
    ) {
      errors.end_fundraising =
        'Event End Fundraising Date should be later than the Event Start Fundraising Date';
    }
  }

  return errors;
};

export const formatDateTime = (
  path: string,
  value: string = format(new Date(), 'yyyy-MM-dd')
): string => {
  const timeZone = { timeZone: 'America/New_York' };
  if (path === '/event/create') {
    return format(new Date(value), 'yyyy-MM-dd');
  } else {
    return formatToTimeZone(new Date(value), 'YYYY-MM-DD', timeZone);
  }
};

export const getFormattedValues = (values: any): object => ({
  ...values,
  start: moment(values.start).utc().format('YYYY-MM-DD HH:mm:ss'),
  end: moment(values.end).utc().format('YYYY-MM-DD HH:mm:ss'),
  start_registration:
    values?.start_registration == null
      ? null
      : moment(values.start_registration).utc().format('YYYY-MM-DD HH:mm:ss'),
  end_registration:
    values?.end_registration == null
      ? null
      : moment(values.end_registration).utc().format('YYYY-MM-DD HH:mm:ss'),
  start_fundraising:
    values?.start_fundraising == null
      ? null
      : moment(values.start_fundraising).utc().format('YYYY-MM-DD HH:mm:ss'),
  end_fundraising:
    values?.end_fundraising == null
      ? null
      : moment(values.end_fundraising).utc().format('YYYY-MM-DD HH:mm:ss'),
});
