import React from 'react';

import { useToast } from '@chakra-ui/core';
import {
  addAdmissionPriceTier,
  createAdmissionMerch,
  deleteAdmissionMerch,
  deleteAdmissionPriceTier,
  updateAdmissionMerch,
  updateTier,
} from 'api/admissionPriceTiers';
import { useMutation } from 'react-query';

import { AdmissionTiersProps } from './AdmissionTiers.props';
import AdmissionTiersView from './AdmissionTiers.view';

const AdmissionTiersContainer = (props: AdmissionTiersProps): JSX.Element => {
  const toast = useToast();
  const [createTierSubmit] = useMutation(addAdmissionPriceTier, {
    onSuccess: () => {
      toast({
        title: 'Success',
        description: 'Price Tier added successfully',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      props.refetchEvent();
    },
    onError: (err) =>
      toast({
        status: 'error',
        title: 'Error',
        //@ts-ignore
        description: err.Message,
        duration: 9000,
        isClosable: true,
      }),
  });

  const [deleteTierSubmit, { isLoading: isDeleting }] = useMutation(
    deleteAdmissionPriceTier,
    {
      onSuccess: () => {
        toast({
          title: 'Success',
          description: 'Price Tier deleted successfully',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        props.refetchEvent();
      },
      onError: (err) =>
        toast({
          status: 'error',
          title: 'Error',
          //@ts-ignore
          description: err.Message,
          duration: 9000,
          isClosable: true,
        }),
    }
  );

  const [updateTierSubmit] = useMutation(updateTier, {
    onSuccess: () => {
      toast({
        title: 'Success',
        description: 'Price Tier updated successfully',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      props.refetchEvent();
    },
    onError: (err) =>
      toast({
        status: 'error',
        title: 'Error',
        //@ts-ignore
        description: err.Message,
        duration: 9000,
        isClosable: true,
      }),
  });

  const [addMerchSubmit] = useMutation(createAdmissionMerch, {
    onSuccess: () => {
      toast({
        title: 'Success',
        description: 'Admission Merch added successfully',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    },
    onError: (err) =>
      toast({
        status: 'error',
        title: 'Error',
        //@ts-ignore
        description: err.Message,
        duration: 9000,
        isClosable: true,
      }),
  });

  const [updateMerchSubmit] = useMutation(updateAdmissionMerch, {
    onSuccess: () => {
      toast({
        title: 'Success',
        description: 'Admission Merch group name updated successfully',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    },
    onError: (err) =>
      toast({
        status: 'error',
        title: 'Error',
        //@ts-ignore
        description: err.Message,
        duration: 9000,
        isClosable: true,
      }),
  });

  const [deleteMerchSubmit, { isLoading: isMerchItemDeleting }] = useMutation(
    deleteAdmissionMerch,
    {
      onSuccess: () => {
        toast({
          title: 'Success',
          description: 'Admission Merch delete successfully',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      },
      onError: (err) =>
        toast({
          status: 'error',
          title: 'Error',
          //@ts-ignore
          description: err.Message,
          duration: 9000,
          isClosable: true,
        }),
    }
  );

  return (
    <AdmissionTiersView
      onCreate={createTierSubmit}
      onUpdate={updateTierSubmit}
      onDelete={deleteTierSubmit}
      isTierDeleting={isDeleting}
      admissionTiers={props.admissionTiers}
      challengeId={props.challengeId}
      onMerchAdd={addMerchSubmit}
      onMerchItemDelete={deleteMerchSubmit}
      isMerchItemDeleting={isMerchItemDeleting}
      onMerchUpdate={updateMerchSubmit}
    />
  );
};

export default AdmissionTiersContainer;
