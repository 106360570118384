import React from 'react';

import { useToast } from '@chakra-ui/core';
import { getActivityTypes } from 'api/activityTypes';
import {
  createScoringRule,
  deleteScoringRule,
  fetchScoringRules,
  updateScoringRule,
} from 'api/scoringRules';
import { useMutation, useQuery } from 'react-query';

import { ScoringRulesProps } from './ScoringRules.props';
import ScoringRulesView from './ScoringRules.view';

const ScoringRulesContainer = (props: ScoringRulesProps): JSX.Element => {
  const toast = useToast();
  const { data: activityTypes = [], isLoading: isActivityFetching } = useQuery(
    'activity-types',
    getActivityTypes
  );
  const { data: scoringRules, refetch } = useQuery(
    `fetch-rules-${props.challengeId}`,
    () => (props.challengeId ? fetchScoringRules(props.challengeId) : undefined)
  );

  const [createSubmit] = useMutation(createScoringRule, {
    onSuccess: () =>
      toast({
        status: 'success',
        description: 'Scoring Rule created successfully',
        duration: 9000,
        isClosable: true,
        title: 'Success',
      }),
    onError: (err) =>
      toast({
        status: 'error',
        //@ts-ignore
        description: err.Message,
        duration: 9000,
        isClosable: true,
        title: 'Error',
      }),
  });

  const [deleteSubmit, { isLoading }] = useMutation(deleteScoringRule, {
    onSuccess: () =>
      toast({
        status: 'success',
        description: 'Scoring Rule deleted successfully',
        duration: 9000,
        isClosable: true,
        title: 'Success',
      }),
    onError: (err) =>
      toast({
        status: 'error',
        //@ts-ignore
        description: err.Message,
        duration: 9000,
        isClosable: true,
        title: 'Error',
      }),
  });

  const [updateSubmit] = useMutation(updateScoringRule, {
    onSuccess: () =>
      toast({
        status: 'success',
        description: 'Scoring Rule updated successfully',
        duration: 9000,
        isClosable: true,
        title: 'Success',
      }),
    onError: (err) =>
      toast({
        status: 'error',
        //@ts-ignore
        description: err.Message,
        duration: 9000,
        isClosable: true,
        title: 'Error',
      }),
  });

  return (
    <ScoringRulesView
      onScoringCreateSubmit={createSubmit}
      activityTypes={activityTypes}
      scoringRules={scoringRules}
      isFetching={isActivityFetching}
      refetch={refetch}
      challengeId={props.challengeId}
      onDeleteSubmit={deleteSubmit}
      isDeleting={isLoading}
      onUpdateSubmit={updateSubmit}
    />
  );
};

export default ScoringRulesContainer;
