import React from 'react';

import { Switch } from '@chakra-ui/core';
import { useField } from 'formik';

import { FormikSwitchProps } from './FormikSwitch.props';

const FormikSwitchView = ({
  name,
  ...props
}: FormikSwitchProps): JSX.Element => {
  const [{ value }, , field] = useField(name);
  return (
    <Switch
      color="purple"
      {...props}
      isChecked={value}
      onChange={() => {
        field.setValue(!value);
        if ((name === 'paid' || name === 'draft') && props.toggleSwitch) {
          props.toggleSwitch(!value);
        }
      }}
    />
  );
};

export default FormikSwitchView;
