import { FormikValues } from 'formik';

export const getDateTime = (): string => {
  const currentDateTime = new Date();
  const minutes = currentDateTime.setMinutes(currentDateTime.getMinutes() + 5);
  const newDateTime = new Date(minutes).toISOString();
  return newDateTime;
};

export const getSchedule = (sendTime?: string | null) => {
  if (new Date(Date.parse(sendTime || '')) > new Date()) {
    return 'Scheduled';
  } else if (sendTime === null) {
    return 'Draft';
  }
};

export const getSendTo = (
  eventId?: number | null,
  teamId?: number | null,
  isEvent?: boolean
) => {
  if (isEvent) {
    if (eventId && teamId) {
      return 'Team';
    } else if (eventId && teamId === null) {
      return 'Event';
    }
  } else {
    if (eventId || teamId) {
      return 'Event';
    } else {
      return 'All Kilter Users';
    }
  }
};

export const setDateTime = (values: FormikValues) => {
  if (values.schedule === 'Instant') {
    return getDateTime();
  } else if (values.schedule === 'Draft') {
    return null;
  } else {
    return values.send_time;
  }
};

export const getInitialValues = (props: any) => ({
  send_to: props.data
    ? getSendTo(props.data.challenge_id, props.data.team_id, props.isEvent)
    : '',
  team_id: props.data?.team_id,
  challenge_id: props.data?.challenge_id,
  schedule: props.data ? getSchedule(props.data?.send_time) : '',
  send_time: props.data?.send_time,
  title: props.data?.title || '',
  body: props.data?.body || '',
  subject: props.data?.subject || '',
});
