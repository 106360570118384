import React from 'react';

import {
  Button,
  CloseButton,
  Collapse,
  Stack,
  useDisclosure,
} from '@chakra-ui/core';
import { Picker } from 'emoji-mart';

import { EmojiPickerProps } from './EmojiPicker.props';

import 'emoji-mart/css/emoji-mart.css';

const EmojiPickerView = (props: EmojiPickerProps): JSX.Element => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <Stack {...props.stackProps}>
      {!isOpen && (
        <Button
          size="sm"
          w="150px"
          bg="#83ff7b"
          onClick={onOpen}
          color="green.600"
        >
          Emoji Selector
        </Button>
      )}
      <Collapse isOpen={isOpen} display="flex" animateOpacity>
        <Picker
          {...props}
          showPreview={false}
          useButton={false}
          showSkinTones={false}
        />
        <CloseButton onClick={onClose} />
      </Collapse>
    </Stack>
  );
};

export default EmojiPickerView;
