import styled from 'utils/styled';

const media = {
	phone: '@media(max-width:700px)',
};
export const Container = styled.div`
	flex: 1;
	padding: 20px;
	background: linear-gradient(324deg, #d9fdff, #45c2cc);
	align-items: center;
	min-height: 100vh;
	display: flex;
	flex-flow: row nowrap;
	${media.phone} {
		flex-flow: column nowrap;
		justify-content: flex-start;
	}
`;