/* eslint-disable react/no-children-prop */
import React from 'react';

import {
  FormControl,
  FormLabel,
  Text,
  FormHelperText,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  InputLeftElement,
} from '@chakra-ui/core';
import { useField } from 'formik';

import { FormikNumberInputProps } from './FormikNumberInput.props';

const FormikNumberInputView = ({
  name,
  label,
  helperText,
  error,
  isPrice,
  ...props
}: FormikNumberInputProps): JSX.Element => {
  const [{ value }, meta, field] = useField(name);
  return (
    <FormControl mt={2} flex={1}>
      {label && (
        <FormLabel htmlFor={name} fontSize="12px" fontWeight="600" color="grey">
          {label}
        </FormLabel>
      )}
      <NumberInput
        size="md"
        id={name}
        {...props}
        value={value}
        onChange={field.setValue}
      >
        {isPrice && (
          <InputLeftElement
            pointerEvents="none"
            fontSize="16px"
            children="$"
            top="8px"
            left="6px"
          />
        )}
        <NumberInputField focusBorderColor="red.200" />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      {helperText && (
        <FormHelperText fontSize="12px" maxW="90%">
          {helperText}
        </FormHelperText>
      )}
      {(error || meta.error) && (
        <Text color="red.500" fontSize="sm">
          {error || meta.error}
        </Text>
      )}
    </FormControl>
  );
};

export default FormikNumberInputView;
