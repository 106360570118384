import React, { useEffect, useState } from 'react';

import { useToast } from '@chakra-ui/core';
import { deleteCharity, fetchAllCharities } from 'api/charities';
import * as R from 'ramda';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Charity } from 'types/charities';

import { CharitiesProps } from './Charities.props';
import CharitiesView from './Charities.view';

const CharitiesContainer = (_: CharitiesProps): JSX.Element => {
  const [deleteCharitySubmit, { isLoading: isDeleteLoading }] = useMutation(
    deleteCharity,
    {
      onSuccess: () => {
        toast({
          title: 'Success',
          description: 'Charity deleted successfully',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });

        refetch();
      },
      onError: (err: any) => {
        toast({
          title: 'Error',
          description: err?.message || err?.Message || 'something went wrong',
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      },
    }
  );

  const {
    data = [],
    isLoading,
    isError,
    refetch,
  } = useQuery('charities', fetchAllCharities);

  const [searchQuery, setSearchQuery] = useState('');
  const onSearchChange = (text: string) => setSearchQuery(text);
  const [columnToSort, setColumnToSort] = useState('');
  const [charities, setCharities] = useState(data);
  const { push } = useHistory();
  const toast = useToast();

  useEffect(() => {
    setCharities(data);
  }, [data]);

  const searchData = (rows: Charity[]) =>
    rows.filter(
      (item) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.id.toString().includes(searchQuery)
    );
  const sortBy = (key: string) => {
    setColumnToSort(key);
    const byKey =
      columnToSort === key
        ? R.descend<any>(R.prop(key))
        : R.ascend<any>(R.prop(key));
    const sortedData = R.sort(byKey, data);
    setCharities(sortedData);
  };

  const onDeleteClick = async (id: number) => {
    await deleteCharitySubmit(id);
    setSearchQuery('');
  };

  return (
    <CharitiesView
      charities={searchData(charities)}
      isLoading={isLoading}
      isError={isError}
      searchQuery={searchQuery}
      onSearchChange={onSearchChange}
      sortBy={sortBy}
      onNewClick={() => push('/charities/create')}
      onDeleteClick={onDeleteClick}
      onEditClick={(id: number) => push(`/charities/update/${id}`)}
      isDeleteLoading={isDeleteLoading}
    />
  );
};

export default CharitiesContainer;
