import React from 'react';

import { Flex, Text } from '@chakra-ui/core';
import LoadingIndicator from 'components/common/LoadingIndicator';
import CharityForm from 'components/modules/CharityForm';

import { CharityUpdateGeneratedProps } from './CharityUpdate.props';

const CharityUpdateView = (props: CharityUpdateGeneratedProps): JSX.Element => (
  <Flex flex={1} direction="column" alignItems="center" pb="200px">
    <Flex maxWidth="1300px" w="100%" p={4} direction="column">
      {props.isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <Text fontSize="30px" fontWeight="600" my={5} color="#4f4f4f">
            Edit Charity Details
          </Text>
          <CharityForm data={props.data} onSubmit={props.onUpdate} />
        </>
      )}
    </Flex>
  </Flex>
);

export default CharityUpdateView;
