import React, { useEffect, useState } from 'react';

import { useToast } from '@chakra-ui/core';
import { createGym, deleteGym, fetchAllGyms, updateGym } from 'api/gym';
import * as R from 'ramda';
import { useMutation, useQuery } from 'react-query';
import { Gym } from 'types/gym';

import { GymProps } from './Gym.props';
import GymView from './Gym.view';

const GymContainer = (props: GymProps): JSX.Element => {
  const {
    data = [],
    isLoading,
    isError,
    refetch,
  } = useQuery('fetchGym', fetchAllGyms);
  const [searchQuery, setSearchQuery] = useState('');
  const [columnToSort, setColumnToSort] = useState('');
  const [gyms, setGyms] = useState(data);
  const onSearchChange = (text: string) => setSearchQuery(text);
  const toast = useToast();

  const [createSubmit] = useMutation(createGym, {
    onSuccess: () => {
      toast({
        status: 'success',
        description: 'Gym created successfully',
        isClosable: true,
        duration: 9000,
        title: 'Success',
      });
      refetch();
    },
    onError: (err) =>
      toast({
        status: 'error',
        //@ts-ignore
        description: err.Message,
        isClosable: true,
        duration: 9000,
        title: 'Error',
      }),
  });

  const [updateSubmit] = useMutation(updateGym, {
    onSuccess: () => {
      toast({
        status: 'success',
        description: 'Gym updated successfully',
        isClosable: true,
        duration: 9000,
        title: 'Success',
      });
      refetch();
    },
    onError: (err) =>
      toast({
        status: 'error',
        //@ts-ignore
        description: err.Message,
        isClosable: true,
        duration: 9000,
        title: 'Error',
      }),
  });

  const [deleteSubmit, { isLoading: isDeleting }] = useMutation(deleteGym, {
    onSuccess: () => {
      toast({
        status: 'success',
        description: 'Gym deleted successfully',
        isClosable: true,
        duration: 9000,
        title: 'Success',
      });
      refetch();
    },
    onError: (err) =>
      toast({
        status: 'error',
        //@ts-ignore
        description: err.Message,
        isClosable: true,
        duration: 9000,
        title: 'Error',
      }),
  });

  useEffect(() => {
    setGyms(data);
  }, [data]);

  const searchData = (rows: Gym[]) =>
    rows.filter(
      (item) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.gym_id.toString().includes(searchQuery)
    );

  const sortBy = (key: string) => {
    setColumnToSort(key);
    const byKey =
      columnToSort === key
        ? R.descend<any>(R.prop(key))
        : R.ascend<any>(R.prop(key));
    const sortedData = R.sort(byKey, data);
    setGyms(sortedData);
  };

  return (
    <GymView
      gyms={searchData(gyms)}
      isLoading={isLoading}
      isError={isError}
      searchQuery={searchQuery}
      onSearchChange={onSearchChange}
      sortBy={sortBy}
      onGymCreate={createSubmit}
      onGymDelete={deleteSubmit}
      onGymUpdate={updateSubmit}
      isDeleting={isDeleting}
    />
  );
};

export default GymContainer;
