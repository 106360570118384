import React, { useState } from 'react';

import {
  Avatar,
  Badge,
  Box,
  Button,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/core';
import LoadingIndicator from 'components/common/LoadingIndicator';
import GymForm from 'components/modules/GymForm';
import ConfirmationModalView from 'components/primitives/ConfirmationModal';
import { AnimatePresence } from 'framer-motion';
import { Gym } from 'types/gym';

import { GymGeneratedProps } from './Gym.props';

const DeleteIcon = ({
  onDeleteClick,
  isLoading,
}: {
  onDeleteClick: () => Promise<any>;
  isLoading: boolean;
}) => {
  return (
    <ConfirmationModalView
      message="Are you sure you want to permanently delete this Gym location?"
      onConfirmClick={onDeleteClick}
      isLoading={isLoading}
    >
      <Icon name="delete" size="14px" color="grey" />
    </ConfirmationModalView>
  );
};

const GymView = (props: GymGeneratedProps): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedGym, setSelectedGym] = useState<Gym | null>(null);
  const [length, setLength] = useState(15);

  return (
    <Box display="flex" justifyContent="center" pb={20}>
      {props.isLoading ? (
        <LoadingIndicator />
      ) : props.isError ? (
        <Text>ERROR</Text>
      ) : (
        <Box maxW="1300px" w="100%" mt={10} m={3}>
          <Text textAlign="center" fontSize="30px" fontFamily="Helvetica Neue">
            All Gyms
          </Text>
          <Flex p={3} justifyContent="center" my={3}>
            <InputGroup
              w="100%"
              maxW="600px"
              boxShadow="0px 1px 7px 0px rgba(50, 50, 50, 0.26)"
            >
              <InputRightElement>
                <Icon name="search" color="gray.300" cursor="pointer" />
              </InputRightElement>
              <Input
                type="phone"
                placeholder="Search Gym by Name or ID"
                borderWidth="0px"
                value={props.searchQuery}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  props.onSearchChange(e.target.value)
                }
              />
            </InputGroup>
          </Flex>
          <Flex flex={1} justifyContent="space-between">
            <Badge
              ml={4}
              variant="outline"
              variantColor="green"
              alignSelf="flex-end"
            >
              TOTAL GYMS - {props.gyms.length}
            </Badge>
            <Button
              mr={4}
              fontSize="14px"
              fontWeight="bold"
              color="white"
              bg="#45C2CC"
              onClick={() => {
                onOpen();
                setSelectedGym(null);
              }}
            >
              CREATE NEW
            </Button>
          </Flex>
          <Stack overflowX="auto" px={4} mt={3}>
            <Flex
              flex={1}
              bg="#f1f1f1"
              p={2}
              minW="1000px"
              fontSize="12px"
              fontWeight="600"
              color="grey"
              borderRadius="5px"
            >
              <Text flex={0.2}>LOGO</Text>
              <Text flex={0.5}>ID</Text>
              <Flex flex={1} mr={2} alignItems="center">
                <Text isTruncated>NAME</Text>
                <Icon
                  name="arrow-up-down"
                  size="8px"
                  ml={1}
                  cursor="pointer"
                  onClick={() => props.sortBy('name')}
                />
              </Flex>
              <Text flex={0.5} mr={2} isTruncated>
                PHONE
              </Text>
              <Text flex={0.5}>CITY</Text>
              <Text flex={0.2}>STATE</Text>
              <Text flex={0.2}>ZIP</Text>
              <Box w="20px" />
            </Flex>
            <Stack bg="Yellow.200" mt={2} minW="1000px">
              {props.gyms.slice(0, length).map((gym) => {
                return (
                  <Flex
                    key={gym.gym_id}
                    flex={1}
                    mb={2}
                    p={1}
                    borderRadius="5px"
                    borderWidth="1px"
                    fontSize="14px"
                    alignItems="center"
                  >
                    <Box flex={0.2}>
                      <Avatar
                        src={`${gym.logo}`}
                        size="sm"
                        borderWidth="0.5px"
                      />
                    </Box>
                    <Text flex={0.5}>{gym.gym_id}</Text>
                    <Text flex={1} mr={2} isTruncated>
                      {gym.name}
                    </Text>
                    <Text flex={0.5} mr={2} isTruncated>
                      {gym.phone}
                    </Text>
                    <Text flex={0.5}>{gym.city}</Text>
                    <Text flex={0.2}>{gym.state}</Text>
                    <Text flex={0.2}>{gym.zip}</Text>
                    <Icon
                      name="edit"
                      color="#4f4f4f"
                      mr={2}
                      mt={1}
                      onClick={() => {
                        onOpen();
                        setSelectedGym(gym);
                      }}
                    />
                    <DeleteIcon
                      onDeleteClick={async () => {
                        await props.onGymDelete(gym.gym_id);
                        props.onSearchChange('');
                      }}
                      isLoading={props.isDeleting}
                    />
                  </Flex>
                );
              })}
            </Stack>
            {length <= props.gyms.length && (
              <Flex justifyContent="center" my={4}>
                <Button
                  w="100px"
                  size="sm"
                  bg="green.400"
                  color="white"
                  onClick={() => setLength(length + 20)}
                  fontSize="12px"
                  _focus={{ borderWidth: 0 }}
                >
                  LOAD MORE
                </Button>
              </Flex>
            )}
          </Stack>
          <AnimatePresence>
            {isOpen && (
              <GymForm
                isModal={isOpen}
                closeModal={onClose}
                onGymCreate={props.onGymCreate}
                onGymUpdate={props.onGymUpdate}
                data={selectedGym}
              />
            )}
          </AnimatePresence>
        </Box>
      )}
    </Box>
  );
};

export default GymView;
