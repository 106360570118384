import React, { createRef, useState } from 'react';

import {
  Badge,
  Button,
  Flex,
  Image,
  SimpleGrid,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/core';
import LoadingIndicator from 'components/common/LoadingIndicator';
import AdmissionTiers from 'components/modules/AdmissionTiers';
import EventDetails from 'components/modules/EventDetails';
import NotificationsTable from 'components/modules/NotificationsTable';
import ScoringRules from 'components/modules/ScoringRules';
import SponsorFormModal from 'components/modules/SponsorForm/SponsorForm.view';
import TeamFormModal from 'components/modules/TeamForm';
import MotionModal from 'components/primitives/MotionModal';
import { FormikProps, FormikValues } from 'formik';
import { AnimatePresence } from 'framer-motion';
import moment from 'moment';
import { Prompt } from 'react-router';
import { Event, Sponsor } from 'types/events';
import { Team } from 'types/team';
import { useResponsive } from 'utils/hooks';

import { S3_BASE } from 'constants/images';

import PlaceHolderImg from '../../res/placeholder.png';
import {
  EventEditGeneratedProps,
  SponsorTabProps,
  TeamsTabProps,
} from './EventEdit.props';

const boxShadow = '0px 0px 10px 0px rgba(50, 50, 50, 0.10)';
const gridWidth: any = ['100%', 'auto'];

const tabProps: any = {
  fontSize: ['xs', 'md'],
  fontWeight: '600',
  _focus: { borderWidth: 0, borderBottomWidth: 2 },
};

const cardItemProps: any = {
  p: 2,
  m: [2, 5],
  bg: 'white',
  borderRadius: '5px',
  boxShadow: boxShadow,
  maxW: ['100%', '250px'],
  direction: ['row', 'column'],
};

const createButtonProps: any = {
  mt: 3,
  size: 'md',
  color: 'white',
  bg: 'purple.900',
  alignSelf: 'flex-end',
};

const cardImageProps: any = {
  w: ['100px', '250px'],
  h: ['100px', '200px'],
};

const TeamsTab = (props: TeamsTabProps) => {
  const [team, setTeam] = useState<Team>();
  const [showModal, setShowModal] = useState(false);

  const onNewCick = () => {
    setShowModal(true);
    setTeam(undefined);
  };

  const onItemClick = (item: any) => () => {
    setShowModal(true);
    setTeam(item);
  };

  return (
    <>
      <AnimatePresence>
        {showModal && (
          <TeamFormModal
            data={team}
            isModal={showModal}
            onSubmit={props.onTeamSubmit}
            closeModal={() => setShowModal(false)}
            onDelete={() => props.onTeamDelete(team?.id || 0)}
          />
        )}
      </AnimatePresence>
      <Stack alignItems="center">
        <Flex justify="flex-end" w="100%">
          <Button {...createButtonProps} onClick={onNewCick}>
            New
          </Button>
        </Flex>
        <SimpleGrid columns={[1, 3]} w={gridWidth}>
          {props.teams.map((item) => {
            const src = item.logo
              ? `${S3_BASE}/partners/${item.logo}`
              : PlaceHolderImg;

            return (
              <Flex
                key={item.id}
                {...cardItemProps}
                onClick={onItemClick(item)}
              >
                <Image src={src} {...cardImageProps} />
                <Stack ml={[5, 0]} spacing={1} overflowX="hidden">
                  <Text isTruncated>{item.name}</Text>
                  <Text fontSize="14px" color="grey">
                    {item.team_url}
                  </Text>
                </Stack>
              </Flex>
            );
          })}
        </SimpleGrid>
      </Stack>
    </>
  );
};

const SponsorsTab = (props: SponsorTabProps) => {
  const [sponsor, setSponsor] = useState<Sponsor>();
  const [showModal, setShowModal] = useState(false);

  const onNewClick = () => {
    setShowModal(true);
    setSponsor(undefined);
  };

  const onItemClick = (item: any) => () => {
    setShowModal(true);
    setSponsor(item);
  };

  return (
    <>
      <AnimatePresence>
        {showModal && (
          <SponsorFormModal
            data={sponsor}
            isModal={showModal}
            allSponsors={props.sponsors}
            onSubmit={props.onSponsorSubmit}
            onDelete={props.onSponsorDelete}
            closeModal={() => setShowModal(false)}
          />
        )}
      </AnimatePresence>
      <Stack alignItems="center">
        <Flex justify="flex-end" w="100%">
          <Button {...createButtonProps} onClick={onNewClick}>
            New
          </Button>
        </Flex>
        <SimpleGrid columns={[1, 3]} w={gridWidth}>
          {props.sponsors.map((item: Sponsor) => {
            const src = item.logo
              ? `${S3_BASE}/sponsors/${item.logo}`
              : PlaceHolderImg;

            return (
              <Flex
                key={item.sponsor_id}
                {...cardItemProps}
                onClick={onItemClick(item)}
              >
                <Image src={src} {...cardImageProps} />
                <Stack ml={[5, 0]} spacing={1}>
                  <Text isTruncated>{item.name}</Text>
                  <Text fontSize="14px" color="grey">
                    {item.website}
                  </Text>
                </Stack>
              </Flex>
            );
          })}
        </SimpleGrid>
      </Stack>
    </>
  );
};

const EventEditView = (props: EventEditGeneratedProps): JSX.Element => {
  const { isMobile } = useResponsive();
  const [tabIndex, setTabIndex] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSubmitting, setSubmitting] = useState(false);
  const formikRef = createRef<FormikProps<FormikValues>>();
  const [isFormChanged, setIsFormChanged] = useState(false);
  const onFormChange = (value: boolean) => setIsFormChanged(value);

  const handleTabsChange = (index: number) => {
    if (!isFormChanged) {
      setTabIndex(index);
    } else {
      onOpen();
    }
  };

  const onEventSave = async () => {
    const values = formikRef.current?.values;
    const formattedValues = {
      ...values,
      start: moment(values?.start).utc().format('YYYY-MM-DD HH:mm:ss'),
      end: moment(values?.end).utc().format('YYYY-MM-DD HH:mm:ss'),
      start_registration:
        values?.start_registration == null
          ? null
          : moment(values.start_registration)
              .utc()
              .format('YYYY-MM-DD HH:mm:ss'),
      end_registration:
        values?.end_registration == null
          ? null
          : moment(values.end_registration).utc().format('YYYY-MM-DD HH:mm:ss'),
      start_fundraising:
        values?.start_fundraising == null
          ? null
          : moment(values.start_fundraising)
              .utc()
              .format('YYYY-MM-DD HH:mm:ss'),
      end_fundraising:
        values?.end_fundraising == null
          ? null
          : moment(values.end_fundraising).utc().format('YYYY-MM-DD HH:mm:ss'),
    };

    setSubmitting(true);
    await props.onSave(formattedValues as Partial<Event>);
    setSubmitting(false);
    onClose();
    setIsFormChanged(false);
  };

  return (
    <Flex flex={1} direction="column" overflowY="auto">
      <Prompt when={isFormChanged} message="you have some unsaved changes" />
      <Flex alignSelf="center">
        <Text
          textAlign="center"
          fontSize={['xl', '3xl']}
          fontWeight="600"
          m={3}
          fontFamily="Avenir Next"
        >
          Edit Event - {props.data?.name}
        </Text>
        {!props.isFetchingEvent && props.data?.draft && (
          <Badge
            fontSize="12px"
            variant="outline"
            variantColor="orange"
            alignSelf="center"
          >
            DRAFT
          </Badge>
        )}
        {!props.isFetchingEvent && !props.data?.draft && (
          <Badge
            fontSize="12px"
            variant="outline"
            variantColor="green"
            alignSelf="center"
          >
            LIVE
          </Badge>
        )}
      </Flex>
      <Flex flex={1} justify="center">
        <Tabs
          index={tabIndex}
          onChange={handleTabsChange}
          size="md"
          align="center"
          maxW="1200px"
          p={5}
          flex={1}
        >
          <TabList borderWidth="2px" borderRadius="5px" mb={5}>
            <Tab {...tabProps}>{isMobile ? 'INFO' : 'EVENT DETAILS'}</Tab>
            {props.data?.teams && <Tab {...tabProps}>TEAMS</Tab>}
            <Tab {...tabProps}>{isMobile ? 'SPONS..' : 'SPONSORS'}</Tab>
            {props.data?.paid && (
              <Tab {...tabProps}>
                {isMobile ? 'TIERS' : 'ADMISSION PRICE TIERS'}
              </Tab>
            )}
            <Tab {...tabProps}>{isMobile ? 'SCORI..' : 'SCORING-RULES'}</Tab>
            <Tab {...tabProps}>{isMobile ? 'NOTIF..' : 'NOTIFICATIONS'}</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              {props.data && !props.isFetchingEvent ? (
                <EventDetails
                  data={props.data}
                  onSubmit={props.onSave}
                  onFormChange={onFormChange}
                  formikRef={formikRef}
                  totalTeams={props.teams.length}
                />
              ) : (
                <LoadingIndicator />
              )}
            </TabPanel>
            {props.data?.teams && (
              <TabPanel>
                <TeamsTab
                  teams={props.teams}
                  onTeamDelete={props.onTeamDelete}
                  onTeamSubmit={props.onTeamSubmit}
                />
              </TabPanel>
            )}
            <TabPanel>
              <SponsorsTab
                sponsors={props.sponsors}
                onSponsorDelete={props.onSponsorDelete}
                onSponsorSubmit={props.onSponsorSubmit}
              />
            </TabPanel>
            {props.data?.paid && (
              <TabPanel>
                <AdmissionTiers
                  admissionTiers={props.admissionTiers}
                  challengeId={props.data?.id || 0}
                  refetchEvent={props.refetchEvent}
                />
              </TabPanel>
            )}
            <TabPanel>
              <ScoringRules challengeId={props.data?.id} />
            </TabPanel>
            <TabPanel>
              <NotificationsTable
                isEvent
                challenge_id={parseInt(props.challengeId || '')}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
        <AnimatePresence>
          {isOpen && isFormChanged && (
            <MotionModal
              isModal={isOpen}
              closeModal={onClose}
              title="Unsaved Changes!!"
              titleProps={{ textAlign: 'center', w: '100%' }}
              h="250px"
              w="320px"
            >
              <Stack spacing={8} mt={10} p={2}>
                <Text fontWeight="500">
                  You have unsaved changes. Would you like to save the changes?
                </Text>
                <Stack isInline flexWrap="wrap">
                  <Button
                    bg="purple.800"
                    color="white"
                    size="sm"
                    w="120px"
                    isLoading={isSubmitting}
                    onClick={onEventSave}
                  >
                    SAVE
                  </Button>
                  <Button
                    bg="#afafaf"
                    color="white"
                    size="sm"
                    w="120px"
                    onClick={onClose}
                  >
                    CANCEL
                  </Button>
                </Stack>
              </Stack>
            </MotionModal>
          )}
        </AnimatePresence>
      </Flex>
    </Flex>
  );
};

export default EventEditView;
