import React from 'react';

import { ThemeProvider, CSSReset } from '@chakra-ui/core';
import { BrowserRouter } from 'react-router-dom';
import Routes from 'routes';

function App(): JSX.Element {
  return (
    <ThemeProvider>
      <>
        <CSSReset />
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </>
    </ThemeProvider>
  );
}

export default App;
