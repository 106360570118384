import React from 'react';

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
} from '@chakra-ui/core';
import FormikInputView from 'components/primitives/FormikInput';
import MotionModal from 'components/primitives/MotionModal';
import { Form, Formik, useField } from 'formik';
import * as R from 'ramda';
import { GymLocation } from 'types/gym';

import { GymFormProps } from './GymForm.props';
import { initialValues } from './GymForm.utils';

// temporary input component
const NumberInput = ({ name, label }: { name: string; label: string }) => {
  const [{ value }, , field] = useField(name);

  return (
    <FormControl flex={1}>
      <FormLabel htmlFor={name} fontSize="12px" fontWeight="600" color="grey">
        {label}
      </FormLabel>
      <Input
        size="sm"
        w="100%"
        value={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (!e.target.value.match(/[a-zA-Z]/g)) {
            field.setValue(e.target.value);
          }
        }}
        id={name}
      />
    </FormControl>
  );
};

const GymFormView = (props: GymFormProps): JSX.Element => {
  const gymLocation = {
    location_id: props.data?.gym_id || 0,
    gym_id: props.data?.gym_info || 0,
    name: props.data?.name || '',
    lat: props.data?.lat || 0,
    lon: props.data?.longi || 0,
    street: props.data?.street || '',
    city: props.data?.city || '',
    state: props.data?.state || '',
    zip: props.data?.zip || '',
  };

  return (
    <Formik<GymLocation>
      initialValues={props.data ? gymLocation : initialValues}
      enableReinitialize
      onSubmit={async (values, formik) => {
        formik.setSubmitting(true);
        const params = {
          ...values,
          lat: parseFloat(values.lat.toString()),
          lon: parseFloat(values.lon.toString()),
        };
        if (props.data) {
          await props.onGymUpdate(params);
        } else {
          await props.onGymCreate(params);
        }
        formik.setSubmitting(false);
        props.closeModal();
      }}
    >
      {({ isSubmitting, values, initialValues: inValues }) => (
        <Form>
          <MotionModal
            title={props.data ? 'Update Gym Location' : 'Create Gym Location'}
            isModal={props.isModal}
            closeModal={props.closeModal}
            w={['98%', '700px']}
            h="650px"
          >
            <Flex flex={1} w="100%" direction="column" p={2}>
              <FormikInputView name="name" label="NAME*" maxW="500px" />
              {/*google auto complete input goes here*/}
              <Flex mt={10} direction={['column', 'row']}>
                <NumberInput name="lat" label="LATITUDE" />
                <Box w="30px" />
                <NumberInput name="lon" label="LONGITUDE" />
              </Flex>
              <Flex direction={['column', 'row']}>
                <FormikInputView name="street" label="STREET" size="sm" />
                <Box w="30px" />
                <FormikInputView name="city" label="CITY" size="sm" />
              </Flex>
              <Flex direction={['column', 'row']}>
                <FormikInputView name="state" label="STATE" size="sm" />
                <Box w="30px" />
                <FormikInputView name="zip" label="ZIP" size="sm" />
              </Flex>
              <Button
                type="submit"
                bg={props.data ? 'yellow.500' : 'purple.800'}
                color="white"
                w="200px"
                mt={8}
                isLoading={isSubmitting}
                isDisabled={R.equals(inValues, values)}
              >
                {props.data ? 'UPDATE' : 'SAVE'}
              </Button>
            </Flex>
          </MotionModal>
        </Form>
      )}
    </Formik>
  );
};

export default GymFormView;
