import React from 'react';

import { useToast } from '@chakra-ui/core';
import { createMerch, deleteMerch, getAllMerch, updateMerch } from 'api/merch';
import { useMutation, useQuery } from 'react-query';

import { MerchProps } from './Merch.props';
import MerchView from './Merch.view';

const MerchContainer = (props: MerchProps): JSX.Element => {
  const toast = useToast();
  const { data, isLoading, refetch } = useQuery('fetch-merch', getAllMerch);
  const [createSubmit] = useMutation(createMerch, {
    onError: (err) =>
      toast({
        status: 'error',
        //@ts-ignore
        description: err.Message,
        isClosable: true,
        duration: 9000,
        title: 'Error',
      }),
    onSuccess: () => {
      toast({
        status: 'success',
        description: 'Merch Catalogue Item created successfully',
        isClosable: true,
        duration: 9000,
        title: 'Success',
      });
      refetch();
    },
  });

  const [updateSubmit] = useMutation(updateMerch, {
    onError: (err) =>
      toast({
        status: 'error',
        //@ts-ignore
        description: err.Message,
        isClosable: true,
        duration: 9000,
        title: 'Error',
      }),
    onSuccess: () => {
      toast({
        status: 'success',
        description: 'Merch Catalogue Item updated successfully',
        isClosable: true,
        duration: 9000,
        title: 'Success',
      });
      refetch();
    },
  });

  const [deleteSubmit, { isLoading: isDeleting }] = useMutation(deleteMerch, {
    onError: (err) =>
      toast({
        status: 'error',
        //@ts-ignore
        description: err.Message,
        isClosable: true,
        duration: 9000,
        title: 'Error',
      }),
    onSuccess: () => {
      toast({
        status: 'success',
        description: 'Merch Catalogue Item deleted successfully',
        isClosable: true,
        duration: 9000,
        title: 'Success',
      });
      refetch();
    },
  });

  return (
    <MerchView
      merchCatalogue={data || []}
      onCreate={createSubmit}
      onUpdate={updateSubmit}
      onDelete={deleteSubmit}
      isDeleting={isDeleting}
      isFetching={isLoading}
    />
  );
};

export default MerchContainer;
