import api from 'api/instance';
import { Charity, CharityDetails } from 'types/charities';

const URL = `${process.env.REACT_APP_API_URL}/charity`;

export const fetchAllCharities = async (): Promise<Charity[]> => {
  const { data } = await api.get<Charity[]>(`${URL}/all`);
  return data;
};

export const createCharity = async (details: CharityDetails): Promise<any> => {
  const { data } = await api.post(`${URL}/create`, details);
  return data;
};

export const deleteCharity = async (id: number): Promise<any> => {
  const { data } = await api.get(`${URL}/delete?charity_id=${id}`);
  return data;
};

export const getCharityDetails = async (id: number): Promise<any> => {
  const { data } = await api.get<CharityDetails>(
    `${URL}/byid/orginfo?charity_id=${id}`
  );
  return data;
};

export const updateCharity = async (details: CharityDetails): Promise<any> => {
  const { data } = await api.post(`${URL}/update`, details);
  return data;
};
